/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  CompetenceDetailViewModel,
  CompetenceVisibleDto,
  CompetenceWeightinessViewModel,
  PerformanceFormDetailDisplayViewModel,
  PerformanceFormDetailDto,
  PerformanceFormDetailEditViewModel,
  SaveCompetenceEvaluationViewModel
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * PerformanceFormDetailApi - API class
 */
export class PerformanceFormDetailApi extends LinqService {

  /**
   * Creates a new PerformanceFormDetailApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: PerformanceFormDetailDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetAsync`;
    let query = this.createQuery<PerformanceFormDetailDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param competenceHeaderId 
   */
  getCompetenceDetailViewModel(competenceHeaderId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetCompetenceDetailViewModel`;
    let query = this.createQuery<CompetenceDetailViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'competenceHeaderId', value: competenceHeaderId && competenceHeaderId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param performanceFormId 
   */
  getCurrentFormCompetenceWeightinessInfo(performanceFormId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetCurrentFormCompetenceWeightinessInfo`;
    let query = this.createQuery<CompetenceWeightinessViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'performanceFormId', value: performanceFormId && performanceFormId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param performanceFormDetailId 
   */
  getPerformanceFormDetailDisplayViewModel(performanceFormDetailId?: string, performanceFormId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetPerformanceFormDetailDisplayViewModel`;
    let query = this.createQuery<PerformanceFormDetailDisplayViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'performanceFormDetailId', value: performanceFormDetailId && performanceFormDetailId.toString() });
    options.params.push({ key: 'performanceFormId', value: performanceFormId && performanceFormId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param performanceFormDetailId 
   */
  getPerformanceFormDetailEditViewModel(performanceFormDetailId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetPerformanceFormDetailEditViewModel`;
    let query = this.createQuery<PerformanceFormDetailEditViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'performanceFormDetailId', value: performanceFormDetailId && performanceFormDetailId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param performanceFormDetailId 
   */
  getPerformanceFormDetailEditViewModelLight(performanceFormDetailId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetPerformanceFormDetailEditViewModelLight`;
    let query = this.createQuery<PerformanceFormDetailEditViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'performanceFormDetailId', value: performanceFormDetailId && performanceFormDetailId.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/GetTranslatedAsync`;
    let query = this.createQuery<PerformanceFormDetailDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/List`;
    let query = this.createQuery<PerformanceFormDetailDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/ListTranslated`;
    let query = this.createQuery<PerformanceFormDetailDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: PerformanceFormDetailDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param saveCompetenceEvaluationViewModel 
   */
  saveCompetenceEmployeeEvaluations(saveCompetenceEvaluationViewModel?: SaveCompetenceEvaluationViewModel, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/SaveCompetenceEmployeeEvaluations`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = saveCompetenceEvaluationViewModel;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param saveCompetenceEvaluationViewModel 
   */
  saveCompetenceManagerEvaluations(saveCompetenceEvaluationViewModel?: SaveCompetenceEvaluationViewModel, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/SaveCompetenceManagerEvaluations`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = saveCompetenceEvaluationViewModel;
    query = query.withOptions(options);

    return query.firstAsync();
  }

   /**
   * @param saveCompetenceEvaluationViewModel 
   */
   ChangeCompetenceVisibility(competenceVisibleDto?: CompetenceVisibleDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormDetail/ChangeCompetenceVisibility`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = competenceVisibleDto;
    query = query.withOptions(options);

    return query.firstAsync();
  }
}
