import './sidebar-menu.scss'

import { Component, Vue } from 'vue-property-decorator'
import { GetFeatureToggle, createGuid } from '../../common/helper'

import { AuthHelper } from '../../common/auth-helper'
import { ConfigApi } from '../../metadata/hrnext-performance-service-api'
import { Globals } from '../../common/globals'
import { createApi } from '../../common'

@Component({
  template: require('./sidebar-menu.pug'),
  props: [
    'canAccessAdmin',
    'isFmoduleEval360Allowed',
    'isFmoduleAnnouncementAllowed',
    'isFmoduleScheduledJobsAllowed',
    'isFmoduleDemoCompanyAllowed',
    'isFmoduleUserStatisticsAllowed',
    'isFmoduleTotalStatisticsAllowed',
    'isFmoduleOkrAllowed',
    'isFmoduleAccessRequestAllowed'
  ]
})
export class SidebarMenuComponent extends Vue {
  // Props
  canAccessAdmin
  isFmoduleEval360Allowed
  isFmoduleAnnouncementAllowed
  isFmoduleScheduledJobsAllowed
  isFmoduleDemoCompanyAllowed
  isFmoduleUserStatisticsAllowed
  isFmoduleTotalStatisticsAllowed
  isFmoduleOkrAllowed
  isFmoduleAccessRequestAllowed
  //

  showComparisonAnalysis = false
  showEvaluation360 = false
  showOkr = false
  systemMenuInAdminSidebar = false
  companyCustomizationParameter = false
  showAnnouncement = false
  showScheduledJobs = false
  showDemoCompany = false
  showUserStatistics = false
  showTotalStatistics = false
  showAccessRequest = false

  configApi: ConfigApi
  menuInfo = []
  usersSecurityItems = {}

  constructor() {
    super()
    this.configApi = createApi(ConfigApi)
  }

  async created(): Promise<void> {
    this.showComparisonAnalysis = await this.isFeatureEnabled(Globals.ComparisonAnalysis)
    this.systemMenuInAdminSidebar = await this.isFeatureEnabled(Globals.SystemMenuInAdminSidebar)
    this.companyCustomizationParameter = await this.isFeatureEnabled(Globals.CompanyCustomizationParameter)

    this.showEvaluation360 = false
    const isFeatureAllowed = await this.isFeatureEnabled(Globals.Evaluation360)
    this.showEvaluation360 = isFeatureAllowed && this.isFmoduleEval360Allowed

    this.$watch('isFmoduleEval360Allowed', () => {
      this.showEvaluation360 = isFeatureAllowed && this.isFmoduleEval360Allowed
    })

    this.showOkr = this.isFmoduleOkrAllowed
    this.$watch('isFmoduleOkrAllowed', () => {
      this.showOkr = this.isFmoduleOkrAllowed
    })

    this.showAnnouncement = this.isFmoduleAnnouncementAllowed
    this.$watch('isFmoduleAnnouncementAllowed', () => {
      this.showAnnouncement = this.isFmoduleAnnouncementAllowed
    })

    this.showScheduledJobs = this.isFmoduleScheduledJobsAllowed
    this.$watch('isFmoduleScheduledJobsAllowed', () => {
      this.showScheduledJobs = this.isFmoduleScheduledJobsAllowed
    })

    this.showDemoCompany = this.isFmoduleDemoCompanyAllowed
    this.$watch('isFmoduleDemoCompanyAllowed', () => {
      this.showDemoCompany = this.isFmoduleDemoCompanyAllowed
    })

    this.showUserStatistics = this.isFmoduleUserStatisticsAllowed
    this.$watch('isFmoduleUserStatisticsAllowed', () => {
      this.showUserStatistics = this.isFmoduleUserStatisticsAllowed
    })

    this.showTotalStatistics = this.isFmoduleTotalStatisticsAllowed
    this.$watch('isFmoduleTotalStatisticsAllowed', () => {
      this.showTotalStatistics = this.isFmoduleTotalStatisticsAllowed
    })

    this.showAccessRequest = this.isFmoduleAccessRequestAllowed
    this.$watch('isFmoduleAccessRequestAllowed', () => {
      this.showAccessRequest = this.isFmoduleAccessRequestAllowed
    })

    this.usersSecurityItems = AuthHelper.getUsersSecurityItemsFromCache()
    await this.buildMenu()
  }

  async isFeatureEnabled(toggle): Promise<boolean> {
    return await GetFeatureToggle(toggle, this.configApi)
  }

  async buildMenu(): Promise<void> {
    const menuInfoCache = Globals.getStorageItem(btoa(Globals.SidebarMenuInfoCacheKey))
    if (menuInfoCache) {
      this.menuInfo = menuInfoCache
    } else {
      await this.setMenu()
      await this.handleMenuPermissions(this.menuInfo)
      Globals.setStorageItem(btoa(Globals.SidebarMenuInfoCacheKey), this.menuInfo, 60 * 60 * 3)
    }
  }

  async handleMenuPermissions(menuInfo: any[]): Promise<boolean> {
    let atLeastOneSubItemVisible = null

    for (let i = 0; i < menuInfo.length; i++) {
      const item = menuInfo[i]
      if (item.to !== null) {
        // Yetki kontrolü
        const isAllowed = AuthHelper.checkPermission(item.to, this.usersSecurityItems)
        if (!isAllowed) {
          // Kontrol başarısız
          item.canUserSee = false
        } else {
          // Kontrol başarılı

          // Feature toogle kontrolü
          if (item.featureVisible === false) {
            item.canUserSee = false
          } else {
            item.canUserSee = true
          }

          if (atLeastOneSubItemVisible === null) {
            atLeastOneSubItemVisible = true
          }
        }
      } else {
        if (item.featureVisible === false) {
          item.canUserSee = false
        } else {
          atLeastOneSubItemVisible = await this.handleMenuPermissions(item.subItems)
          item.canUserSee = atLeastOneSubItemVisible
        }
      }
    }
    return atLeastOneSubItemVisible
  }

  async setMenu(): Promise<void> {
    this.menuInfo = [
      {
        iconPack: 'fas',
        icon: 'users',
        title: this.$t('components.sidebarMenu.adminDashboard'),
        to: '/admin-dashboard',
        canUserSee: false
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-books',
        title: this.$t('components.sidebarMenu.libraries'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-bullseye-arrow',
            title: this.$t('components.sidebarMenu.targetLibrary'),
            to: null,
            canUserSee: false,
            subItems: [
              {
                iconPack: 'fas',
                icon: 'bullseye',
                title: this.$t('components.sidebarMenu.targetGroups'),
                to: '/target-library-group-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'bullseye',
                title: this.$t('components.sidebarMenu.targets'),
                to: '/target-library-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'bullseye',
                title: this.$t('components.sidebarMenu.strategicPriorities'),
                to: '/company-priorities-library-list',
                canUserSee: false
              }
            ]
          },
          {
            iconPack: 'fas',
            icon: 'user-shield',
            title: this.$t('components.sidebarMenu.competencyLibrary'),
            to: null,
            canUserSee: false,
            subItems: [
              {
                iconPack: 'fas',
                icon: 'shield-alt',
                title: this.$t('components.sidebarMenu.competenceGroups'),
                to: '/competence-group-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'shield-alt',
                title: this.$t('components.sidebarMenu.competenceLevels'),
                to: '/competence-level',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'shield-alt',
                title: this.$t('components.sidebarMenu.competences'),
                to: '/competence-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'address-book',
                title: this.$t('components.sidebarMenu.employeeLevels'),
                to: '/employee-level-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'address-card',
                title: this.$t('components.sidebarMenu.employeeSubLevels'),
                to: '/employee-sub-level-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'th-large',
                title: this.$t('components.sidebarMenu.competenceAssignmentMatrix'),
                to: '/competence-matrix',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'th-large',
                title: this.$t('components.sidebarMenu.competenceScala'),
                to: '/competence-evaluation-scale-list',
                canUserSee: false
              },
              {
                iconPack: 'fas',
                icon: 'th-large',
                title: this.$t('components.sidebarMenu.definingTag'),
                to: '/performance-form-detail-tag-list',
                canUserSee: false
              }
            ]
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-repeat-alt-circle',
        title: this.$t('components.sidebarMenu.okrAdaptations'),
        to: null,
        canUserSee: false,
        featureVisible: this.showOkr,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-users',
            title: this.$t('components.sidebarMenu.teams'),
            to: null,
            canUserSee: false,
            subItems: [
              {
                iconPack: 'icomoon',
                icon: 'icon-users',
                title: this.$t('components.sidebarMenu.teamList'),
                to: '/okr-team-list',
                canUserSee: false
              },
              {
                iconPack: 'icomoon',
                icon: 'icon-users',
                title: this.$t('components.sidebarMenu.assignToTeam'),
                to: '/assigning-okr-team',
                canUserSee: false
              }
            ]
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-bullseye-arrow',
            title: this.$t('components.sidebarMenu.okrObjectives'),
            to: null,
            canUserSee: false,
            subItems: [
              {
                iconPack: 'icomoon',
                icon: 'icon-bullseye-arrow',
                title: this.$t('components.sidebarMenu.okrCompanyStrategyList'),
                to: '/okr-company-strategy-list',
                canUserSee: false
              }
            ]
          },
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.okrPeriod'),
            to: '/okr-period-list',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'far',
        icon: 'check-square',
        title: this.$t('components.sidebarMenu.approval'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.performanceApprovalType'),
            to: '/performance-approval-types-list',
            canUserSee: false
          },
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.determiningPerformanceApprovalSteps'),
            to: '/performance-approval-flow-list',
            canUserSee: false
          },
          {
            iconPack: 'far',
            icon: 'check-square',
            title: this.$t('components.sidebarMenu.employeeFormApprovalDetermination'),
            to: '/performance-form-period-approval-edit',
            canUserSee: false
          },
          {
            iconPack: 'far',
            icon: 'check-square',
            title: this.$t('components.sidebarMenu.bulkApproverChange'),
            to: '/performance-approval-substitution',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'fas',
        icon: 'users',
        title: this.$t('components.sidebarMenu.employeeGroup'),
        to: '/employee-group-list',
        canUserSee: false
      },
      {
        iconPack: 'fas',
        icon: 'users',
        title: this.$t('components.sidebarMenu.demoCompanies'),
        to: '/demo-company-list',
        canUserSee: false,
        featureVisible: this.showDemoCompany
      },
      {
        iconPack: '',
        icon: 'format-page-break',
        title: this.$t('components.sidebarMenu.performanceFormTemplate'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.operationYear'),
            to: '/performance-form-operation-year-list',
            canUserSee: false
          },
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.templateGroups'),
            to: '/performance-form-template-group-list',
            canUserSee: false
          },
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.performanceFormTemplates'),
            to: '/performance-form-template-list',
            canUserSee: false
          },
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.assigningPerformanceFormTemplate'),
            to: '/assigning-performance-form-template',
            canUserSee: false
          },
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.performanceEvaluationScala'),
            to: '/performance-evaluation-scale-list',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'fas',
        icon: 'cog',
        title: this.$t('components.sidebarMenu.formUpdateAndApprovalReminder'),
        to: '/employee-performance-form-edit-allforms',
        canUserSee: false
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-bell',
        title: this.$t('components.sidebarMenu.notifications'),
        to: null,
        canUserSee: false,
        subItems: [
          // {
          //   iconPack: 'icomoon',
          //   icon: 'icon-bell',
          //   title: this.$t('components.sidebarMenu.notifications'),
          //   to: '/notification-list',
          //   canUserSee: false
          // },
          {
            iconPack: 'icomoon',
            icon: 'icon-bell',
            title: this.$t('components.sidebarMenu.notificationsMultiLang'),
            to: '/notification-list-multilanguage',
            canUserSee: false
          },
          {
            iconPack: 'fas',
            icon: 'mobile-alt',
            title: this.$t('components.sidebarMenu.instantMobileNotification'),
            to: '/instant-mobile-notification',
            canUserSee: false
          },
          {
            iconPack: 'fas',
            icon: 'mail-bulk',
            title: this.$t('components.sidebarMenu.instantNotification'),
            to: '/instant-notification',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'fas',
        icon: 'users-cog',
        title: this.$t('components.sidebarMenu.userGroupManagement'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: 'fas',
            icon: 'users-cog',
            title: this.$t('components.sidebarMenu.userGroupMatching'),
            to: '/usergroup-detail'
          },
          {
            iconPack: 'fas',
            icon: 'user-check',
            title: this.$t('components.sidebarMenu.userModuleManagement'),
            to: '/user-module-management'
          },
          {
            iconPack: 'fas',
            icon: 'user-lock',
            title: this.$t('components.sidebarMenu.unlockEmployees'),
            to: '/locked-employees'
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-poll-check',
        title: this.$t('components.sidebarMenu.evaluation'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-poll-check',
            title: this.$t('components.sidebarMenu.startEvaluationPeriod'),
            to: '/evaluation-period-start',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-poll-check',
            title: this.$t('components.sidebarMenu.evaluationSurveyQuestions'),
            to: '/evaluation-question-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-poll-check',
            title: this.$t('components.sidebarMenu.evaluationSurveyQuestionsForEmployee'),
            to: '/evaluation-question-list-for-employee',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-poll-check',
            title: this.$t('components.sidebarMenu.mandotaryTargetEvaluationResult'),
            to: '/mandotary-target-evaluation-result',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-poll-check',
            title: this.$t('components.sidebarMenu.calibration'),
            to: '/calibration',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-feedback-alt',
        title: this.$t('components.sidebarMenu.comparisonAnalysis'),
        to: null,
        canUserSee: false,
        featureVisible: this.showComparisonAnalysis,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-feedback-alt',
            title: this.$t('components.sidebarMenu.comparisonGroups'),
            to: '/comparison-group-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-feedback-alt',
            title: this.$t('components.sidebarMenu.assigningComparisonGroup'),
            to: '/assigning-comparison-group',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-folder-export',
        title: this.$t('components.sidebarMenu.reports'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-folder-export',
            title: this.$t('components.sidebarMenu.excelReports'),
            to: '/reports',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-folder-export',
            title: this.$t('components.sidebarMenu.usageStatistics'),
            to: '/usage-statistics',
            canUserSee: false,
            featureVisible: !this.showTotalStatistics
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-folder-export',
            title: this.$t('components.sidebarMenu.userStatistics'),
            to: '/user-statistics',
            canUserSee: false,
            featureVisible: this.showUserStatistics
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-folder-export',
            title: this.$t('components.sidebarMenu.totalUsageStatistics'),
            to: '/total-usage-statistics',
            canUserSee: false,
            featureVisible: this.showTotalStatistics
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-c360',
        title: this.$t('components.sidebarMenu.threeSixty'),
        to: null,
        canUserSee: false,
        featureVisible: this.showEvaluation360,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyCompetenceScala'),
            to: '/three-sixty-competence-evaluation-scale-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.definingEvaluatorRoles'),
            to: '/evaluator-roles-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.defining360DegreeGroups'),
            to: '/evaluation-360-group-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.assigning360DegreeGroup'),
            to: '/evaluation-360-group-assignment',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.defining360DegreePeriods'),
            to: '/period-360-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyPeriodStartEnd'),
            to: '/evaluation-360-period-start-end',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyEvaluatorsList'),
            to: '/three-sixty-evaluators-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyEmployeeLevel'),
            to: '/three-sixty-employee-level',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyEmployeeApproval'),
            to: '/three-sixty-employee-approval',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyEmployeeEvaluator'),
            to: '/three-sixty-employee-evaluator',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-c360',
            title: this.$t('components.sidebarMenu.threeSixtyFormDetailView'),
            to: '/three-sixty-form-edit-allforms',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'fas',
        icon: 'cog',
        title: this.$t('components.sidebarMenu.scheduledJobs'),
        to: null,
        canUserSee: false,
        featureVisible: this.showScheduledJobs,
        subItems: [
          {
            iconPack: 'fas',
            icon: 'clock',
            title: this.$t('components.sidebarMenu.scheduledJobList'),
            to: '/scheduled-job-list',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-bell',
        title: this.$t('components.sidebarMenu.companyCustomizationParameter'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-bell',
            title: this.$t('components.sidebarMenu.companyCustomizationParameter'),
            to: '/company-customization-parameter-list',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-bell',
            title: this.$t('components.sidebarMenu.performanceFormDataTransferInfo'),
            to: '/performance-form-data-transfer-info',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-bell',
            title: this.$t('components.sidebarMenu.integrationHistory'),
            to: '/integration-history-list',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-question-drop',
        title: this.$t('components.sidebarMenu.support'),
        to: null,
        canUserSee: false,
        subItems: [
          {
            iconPack: 'icomoon',
            icon: 'icon-question-drop',
            title: this.$t('components.sidebarMenu.support'),
            to: '/support',
            canUserSee: false
          },
          {
            iconPack: 'icomoon',
            icon: 'icon-clock',
            title: this.$t('components.sidebarMenu.companyAccessRequest'),
            to: '/access-request-result-list',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: '',
        icon: 'format-page-break',
        title: this.$t('components.sidebarMenu.announcement'),
        to: null,
        canUserSee: false,
        featureVisible: this.showAnnouncement,
        subItems: [
          {
            iconPack: '',
            icon: 'format-page-break',
            title: this.$t('components.sidebarMenu.announcement'),
            to: '/announcement',
            canUserSee: false
          }
        ]
      },
      {
        iconPack: 'icomoon',
        icon: 'icon-clock',
        title: this.$t('components.sidebarMenu.accessRequest'),
        to: '/access-request',
        canUserSee: false,
        featureVisible: this.showAccessRequest
      }
    ]
  }
}
