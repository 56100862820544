import './portal-navigation.scss'

import {
  CompanySettingApi,
  CompanySettingParameterApi,
  CompanySettingParameterQueryDto,
  CompanySettingTranslatedDto,
  ConfigApi,
  MeetingApi
} from '../../metadata/hrnext-performance-service-api'

import { BaseComponent } from '../../common/base-component'
import { Component } from 'vue-property-decorator'
import { DialogProgrammatic as Dialog } from 'buefy'
import { Globals } from '../../common/globals'
import Notiflix from 'notiflix'
import { SearchBoxComponent } from './search-box/search-box'
import { createApi } from '../../common'

@Component({
  template: require('./portal-navigation.pug'),
  props: ['currentUser', 'canAccessAdmin'],
  components: {
    'search-box': SearchBoxComponent
  }
})
export class PortalNavigationComponent extends BaseComponent {
  currentUser
  canAccessAdmin
  companySettingApi: CompanySettingApi
  meetingApi: MeetingApi
  configApi: ConfigApi
  companySettingParameterApi: CompanySettingParameterApi
  companySettingTranslatedDto: CompanySettingTranslatedDto[] = []
  companySettingParameterQueryDto: CompanySettingParameterQueryDto[] = []

  constructor() {
    super()
    this.companySettingApi = createApi(CompanySettingApi)
    this.meetingApi = createApi(MeetingApi)
    this.companySettingParameterApi = createApi(CompanySettingParameterApi)
    this.configApi = createApi(ConfigApi)
  }

  async created(): Promise<void> {
    await super.created(true)
  }

  toggleHamburger(): void {
    const burger = document.querySelector('.burger')
    const nav = document.querySelector('#main-navbar')

    burger.classList.toggle('is-active')
    nav.classList.toggle('is-active')
  }

  changeOperationYear(): void {
    Dialog.confirm({
      title: this.$t('components.portalNavigation.Warning') as string,
      message: this.$t('components.portalNavigation.changeYearWarningMessage') as string,
      confirmText: this.$t('components.portalNavigation.Approve') as string,
      cancelText: this.$t('components.portalNavigation.Cancel') as string,
      type: 'is-info',
      hasIcon: true,
      onConfirm: async () => this.processChangeOperationYear()
    })
  }

  processChangeOperationYear(): void {
    this.updateCurrentYear()
    const currentUrl = this.$route.path.toLowerCase()
    if (currentUrl.includes('dashboard')) {
      this.$router.go(0) // to refresh page
    } else {
      this.$router.push('/dashboard')
    }
  }

  goToNotifications(): void {
    this.$router.push('../notifications')
  }

  async requestMeeting(): Promise<void> {
    Dialog.confirm({
      title: this.$t('pages.employeePerformanceFormEdit.dialogTitle2') as string,
      message: this.$t('pages.employeePerformanceFormEdit.dialogMessage2') as string,
      confirmText: this.$t('pages.employeePerformanceFormEdit.confirmText2') as string,
      cancelText: this.$t('pages.employeePerformanceFormEdit.cancelText') as string,
      type: 'is-info',
      hasIcon: true,
      onConfirm: async () => {
        await this.meetingApi.requestMeetingAsync()
        Notiflix.Notify.success(this.$t('pages.employeePerformanceFormEdit.requestMeetingForwarded') as string)
      }
    })
  }
}
