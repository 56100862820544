import './target-bar.scss'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  template: require('./target-bar.pug'),
  props: {
    actual: {
      type: Number,
      default: function(): number {
        return 0
      }
    },
    maximum: {
      type: Number,
      default: function(): number {
        return 10
      }
    },
    empty: {
      type: Boolean,
      default: false
    },
    isFormInRevision: {
      type: Boolean,
      default: false
    }
  }
})
export class TargetBarComponent extends Vue {
  constructor() {
    super()
  }

  // Props
  private empty: number
  private actual: number
  private maximum: number

  // Hesaplanmış özellik
  get width(): number {
    if (this.empty) {
      return 0
    }
    const actualValue = this.actual as number
    const maximumValue = this.maximum as number
    return Math.min(100, Math.max(0, (actualValue / maximumValue) * 100))
  }

  // Hedef çubuğu için CSS değişkenleri
  get targetBarCssVars(): Record<string, string> {
    return {
      '--progress-width': `${this.width}%`
    }
  }
}
