/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AccessRequestApprovedCompanyViewModel {
  requestCompanyId?: string
}

export interface AccessRequestDto {
  serviceManagerNo?: string
  reason?: string
  requestDateUTC?: Date
  accessDay?: number
  requestEmployeeId?: string
  approvalStatus?: AccessRequestDtoApprovalStatusEnum
  requestCompanyId?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  product?: AccessRequestDtoProductEnum
}

/**
 * Enum for the approvalStatus property.
 */
export type AccessRequestDtoApprovalStatusEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the AccessRequestProduct property.
 */
export type AccessRequestDtoProductEnum = '0' | '1' | '2'

export interface AccessRequestResultDto {
  accessRequestId?: string
  accessStartDateUTC?: Date
  accessEndDateUTC?: Date
  responseByEmployeeId?: string
  description?: string
  isApproved?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface AccessRequestViewModel {
  id?: string
  serviceManagerNo?: string
  reason?: string
  requestDateUTC?: Date
  accessDay?: number
  requesterUserName?: string
  approvalStatus?: AccessRequestViewModelApprovalStatusEnum
  requestedCompanyName?: string
  productType?: number
}

/**
 * Enum for the approvalStatus property.
 */
export type AccessRequestViewModelApprovalStatusEnum = '0' | '1' | '2' | '3'

export interface ActionState {
  actionStateType?: ActionStateActionStateTypeEnum
  actionStateInformation?: Array<string>
}

/**
 * Enum for the actionStateType property.
 */
export type ActionStateActionStateTypeEnum = '1' | '2' | '3' | '4' | '5'

export interface ActionStateViewModel {
  totalActionStateCount?: number
  actionStates?: Array<ActionState>
}

export interface ActivePeriodTypeOfCurrentPerformanceFormViewModel {
  activePeriodType?: ActivePeriodTypeOfCurrentPerformanceFormViewModelActivePeriodTypeEnum
}

/**
 * Enum for the activePeriodType property.
 */
export type ActivePeriodTypeOfCurrentPerformanceFormViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface AddressDto {
  detail?: string
  employeeId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface AnnouncementAppliactionSelectBulkRequestDto {
  applicationTypesList?: Array<AnnouncementAppliactionSelectBulkRequestDtoApplicationTypesListEnum>
  announcementId?: string
}

/**
 * Enum for the applicationTypesList property.
 */
export type AnnouncementAppliactionSelectBulkRequestDtoApplicationTypesListEnum = '0' | '1' | '2'

export interface AnnouncementApplicationDto {
  announcementApplicationTypes?: AnnouncementApplicationDtoAnnouncementApplicationTypesEnum
  announcementId?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the announcementApplicationTypes property.
 */
export type AnnouncementApplicationDtoAnnouncementApplicationTypesEnum = '0' | '1' | '2'

export interface AnnouncementCompanyBulkRequestDto {
  companyIdList?: Array<string>
  announcementId?: string
}

export interface AnnouncementCompanyDto {
  companyId?: string
  announcementId?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface AnnouncementDto {
  endDate?: Date
  isActive?: boolean
  isPublished?: boolean
  announcementType?: AnnouncementDtoAnnouncementTypeEnum
  translations?: Array<AnnouncementTranslationDto>
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the announcementType property.
 */
export type AnnouncementDtoAnnouncementTypeEnum = '0' | '1' | '2' | '3'

export interface AnnouncementTranslatedDto {
  beginDate?: Date
  endDate?: Date
  isActive?: boolean
  isPublished?: boolean
  content?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface AnnouncementTranslationDto {
  announcementId?: string
  content?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ApplicationVersionDto {
  channelType?: ApplicationVersionDtoChannelTypeEnum
  version?: string
  isForced?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the channelType property.
 */
export type ApplicationVersionDtoChannelTypeEnum = '0' | '1' | '2'

export interface ApprovalCountsDto {
  completedCount?: number
  unCompletedCount?: number
}

export interface ApprovalFlowCountDto {
  name?: string
  count?: number
  fixedApprovalType?: number
}

export interface ApprovalFlowReportDto {
  year?: number
  companyName?: string
  employeeNo?: string
  employeeName?: string
  employeeSurname?: string
  departmentName?: string
  activePeriodTypeName?: string
  performanceApprovalTypeName?: string
  approvalEmployeeName?: string
  approvalEmployeeSurname?: string
  approvalEmployeeNo?: string
}

export interface ApprovalStatusReportDto {
  year?: number
  companyName?: string
  employeeNo?: string
  employeeName?: string
  employeeSurname?: string
  departmentName?: string
  activePeriodTypeName?: string
  approvalStatus?: string
}

export interface ApproveThreeSixtyEvaluatorsRequest {
  evaluatees?: Array<ApproveThreeSixtyEvaluatorsRequestEvaluatee>
}

export interface ApproveThreeSixtyEvaluatorsRequestEvaluatee {
  threeSixtyPeriodId?: string
  threeSixtyEvaluateeEmployeeId?: string
}

export interface AssignPerformanceFormConditionalResult {
  hasWarning?: boolean
  assignPerformanceFormConditionalResults?: Array<AssignPerformanceFormConditionalViewModel>
}

export interface AssignPerformanceFormConditionalViewModel {
  errorTitle?: string
  errorContent?: string
  employees?: Array<EmployeeDto>
  resultType?: AssignPerformanceFormConditionalViewModelResultTypeEnum
}

/**
 * Enum for the resultType property.
 */
export type AssignPerformanceFormConditionalViewModelResultTypeEnum = '1' | '2' | '3' | '4' | '5' | '6'

export interface ChangedActiveApproversQuery {
  performanceFormId?: string
  employeeId?: string
  employeeName?: string
  employeeSurname?: string
  employeeNo?: string
  managerId?: string
  managerName?: string
  managerSurname?: string
  managerNo?: string
  approverId?: string
  approverName?: string
  approverSurname?: string
  approverNo?: string
  fixedApprovalType?: string
  approvalTypeId?: string
}

export interface ClarificationTextAcceptanceHistoryDto {
  clarificationTextId?: string
  acceptDateUTC?: Date
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ClarificationTextDto {
  isActive?: boolean
  isApproveNeeded?: boolean
  translations?: Array<ClarificationTextTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ClarificationTextTranslatedDto {
  isActive?: boolean
  isApproveNeeded?: boolean
  name?: string
  description?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ClarificationTextTranslationDto {
  clarificationTextId?: string
  name?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CollarDto {
  code?: string
  translations?: Array<CollarTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CollarTranslatedDto {
  code?: string
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CollarTranslationDto {
  collarId?: string
  name?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CombinedPerformanceFormDetail {
  performanceFormDetails?: Array<CombinedPerformanceFormDetail>
  name?: string
  title?: string
  description?: string
  weight?: number
  employeeSuccessRate?: number
  managerSuccessRate?: number
  formDetailType?: CombinedPerformanceFormDetailFormDetailTypeEnum
}

/**
 * Enum for the formDetailType property.
 */
export type CombinedPerformanceFormDetailFormDetailTypeEnum = '0' | '1' | '2' | '3' | '4'

export interface CombinedPerformanceFormDetailViewModel {
  year?: number
  firstLevelPerformanceFormDetails?: Array<CombinedPerformanceFormDetail>
}

export interface CombinedPerformanceFormViewModel {
  performanceFormId?: string
  year?: number
  employeeSuccessRate?: string
  managerSuccessRate?: string
}

export interface CommentDto {
  commentDateUtc?: Date
  commentType?: CommentDtoCommentTypeEnum
  commentSourceType?: CommentDtoCommentSourceTypeEnum
  commentSourceId?: string
  commentContent?: string
  isInformToManager?: boolean
  fromEmployeeId?: string
  fromEmployeeName?: string
  fromEmployeeImageFileName?: string
  toEmployeeId?: string
  parentCommentId?: string
  childComments?: Array<CommentDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the commentType property.
 */
export type CommentDtoCommentTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the commentSourceType property.
 */
export type CommentDtoCommentSourceTypeEnum = '0' | '1' | '2' | '3'

export interface CommentFilter {
  commentTypes?: Array<CommentFilterCommentTypesEnum>
  commentSourceId?: string
  commentContent?: string
  fromEmployeeId?: string
}

/**
 * Enum for the commentTypes property.
 */
export type CommentFilterCommentTypesEnum = '0' | '1' | '2' | '3'

export interface CommentReportDto {
  location?: string
  employeeNo?: string
  name?: string
  surname?: string
  targetOrCompetenceName?: string
  commentType?: string
  commentContent?: string
  fromEmployeeName?: string
  fromEmployeeSurname?: string
}

export interface CommentRequestDto {
  commentType?: CommentRequestDtoCommentTypeEnum
  commentSourceType?: CommentRequestDtoCommentSourceTypeEnum
  commentSourceId?: string
  isCommentGiven?: boolean
  fromEmployeeId?: string
  toEmployeeId?: string
  requestorEmployeeId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the commentType property.
 */
export type CommentRequestDtoCommentTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the commentSourceType property.
 */
export type CommentRequestDtoCommentSourceTypeEnum = '0' | '1' | '2' | '3'

export interface CommentViewModel {
  comments?: Array<CommentDto>
  isShowComment?: boolean
  isMyForm?: boolean
  commentRequestedEmployees?: string
}

export interface Company {
  code?: string
  name?: string
  parentCompanyId?: string
  parentCompany?: Company
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanyAccessRequestsViewModel {
  id?: string
  reason?: string
  requestDateUTC?: Date
  approvalStatus?: CompanyAccessRequestsViewModelApprovalStatusEnum
  accessRequestResultId?: string
}

/**
 * Enum for the approvalStatus property.
 */
export type CompanyAccessRequestsViewModelApprovalStatusEnum = '0' | '1' | '2' | '3'

export type CompanyStatusEnum = '0' | '1' | '2'

export interface CompanyDto {
  code?: string
  name?: string
  parentCompanyId?: string
  parentCompany?: CompanyDto
  isDeleted?: boolean
  isDemoCompany?: boolean
  IsActiveForDemoCompany?: boolean
  companyStatusEnum?: CompanyStatusEnum
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanyInformationDto {
  id?: string
  code?: string
  name?: string
  expirationDate?: string
  companyId?: string
  companyDescription?: string
  ContactMail?: string
  userCount?: number
  usageDay?: number
  companyStatus?: number
}

export interface CompanyFeatureModuleInfoViewModel {
  companyFeatureModules?: Array<CompanyFeatureModuleViewModel>
}

export interface CompanyFeatureModuleViewModel {
  id?: string
  companyId?: string
  featureModuleId?: string
  featureModuleCode?: string
  isAllowed?: boolean
}

export interface CompanyPrioritiesLibraryDto {
  isActive?: boolean
  translations?: Array<CompanyPrioritiesLibraryTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanyPrioritiesLibraryTranslatedDto {
  isActive?: boolean
  name?: string
  description?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanyPrioritiesLibraryTranslationDto {
  companyPrioritiesLibraryId?: string
  name?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanySettingDto {
  year?: number
  order?: number
  isActive?: boolean
  imageFileName?: string
  translations?: Array<CompanySettingTranslationDto>
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanySettingParameterDto {
  parameterId?: CompanySettingParameterDtoParameterIdEnum
  parameterValue?: number
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the parameterId property.
 */
export type CompanySettingParameterDtoParameterIdEnum = '1'

export interface CompanySettingParameterQueryDto {
  id?: string
  companyId?: string
  parameterId?: CompanySettingParameterQueryDtoParameterIdEnum
  parameterValue?: number
  performanceFormOperationYearId?: string
}

/**
 * Enum for the parameterId property.
 */
export type CompanySettingParameterQueryDtoParameterIdEnum = '1'

export interface CompanySettingTranslatedDto {
  isActive?: boolean
  title?: string
  description?: string
  year?: number
  imageFileName?: string
  order?: number
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompanySettingTranslationDto {
  companySettingId?: string
  title?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ComparisonAnalysisChartDataResultDto {
  numberOfPeople?: number
  grade?: number
}

export interface ComparisonAnalysisEmployeeDto {
  employee?: ComparisonAnalysisScoreDto
  company?: ComparisonAnalysisScoreDto
  comparisonGroups?: Array<ComparisonAnalysisScoreDto>
}

export interface ComparisonAnalysisGroupDto {
  comparisonGroup?: ComparisonAnalysisScoreDto
  company?: ComparisonAnalysisScoreDto
  employees?: Array<ComparisonAnalysisScoreDto>
}

export interface ComparisonAnalysisScoreDto {
  calculationType?: ComparisonAnalysisScoreDtoCalculationTypeEnum
  name?: string
  score?: number
}

/**
 * Enum for the calculationType property.
 */
export type ComparisonAnalysisScoreDtoCalculationTypeEnum = '0' | '1' | '2' | '3'

export interface ComparisonAnalysisSearchDto {
  employeeId?: string
  positionGroups?: Array<string>
  year?: number
  yearParams?: YearParameter
  subordinate?: number
}

export interface ComparisonGroupCalculatedDto {
  calculationType?: ComparisonGroupCalculatedDtoCalculationTypeEnum
  comparisonGroupId?: string
  employeeId?: string
  year?: number
  yearId?: string
  score?: number
  includedCompanyComparisonAnalysis?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the calculationType property.
 */
export type ComparisonGroupCalculatedDtoCalculationTypeEnum = '0' | '1' | '2' | '3'

export interface ComparisonGroupDto {
  isActive?: boolean
  minimumEvaluationCount?: number
  translations?: Array<ComparisonGroupTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ComparisonGroupTranslatedDto {
  isActive?: boolean
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ComparisonGroupTranslationDto {
  name?: string
  comparisonGroupId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceAndBehaviorIndicatorsIsUsedDto {
  competenceId?: string
  competenceBehaviorIndicatorIds?: Array<string>
}

export interface CompetenceAssignmentTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceBehaviorIndcatorScaleDto {
  behaviorIndicatorName?: string
  employeeCompetenceEvaluationScaleId?: string
  managerCompetenceEvaluationScaleNId?: string
}

export interface CompetenceBehaviorIndicatorDto {
  competenceId?: string
  competence?: CompetenceDto
  competenceLevel?: CompetenceLevelDto
  translations?: Array<CompetenceBehaviorIndicatorTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceBehaviorIndicatorQueryDto {
  competenceBehaviorIndicatorId?: string
  name?: string
}

export interface CompetenceBehaviorIndicatorTranslatedDto {
  name?: string
  competenceLevelId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceBehaviorIndicatorTranslationDto {
  name?: string
  competenceBehaviorIndicatorId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceDetailViewModel {
  performanceFormId?: string
  employeeeId?: string
  year?: number
  activePeriodType?: CompetenceDetailViewModelActivePeriodTypeEnum
  isMyForm?: boolean
  competenceGroups?: Array<PerformanceFormCompetenceGroupQueryDto>
  isActiveApproval?: boolean
  isAdminView?: boolean
  approvalOrder?: number
  performanceFormOperationYearId?: string
}

/**
 * Enum for the activePeriodType property.
 */
export type CompetenceDetailViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface CompetenceDto {
  isActive?: boolean
  comment?: string
  competenceGroup?: CompetenceGroupDto
  translations?: Array<CompetenceTranslationDto>
  competenceBehaviorIndicators?: Array<CompetenceBehaviorIndicatorDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceEmployeeEvaluationViewModel {
  competenceName?: string
  competenceGroupName?: string
  competenceDescription?: string
  competenceBehaviorIndicators?: Array<CompetenceBehaviorIndcatorScaleDto>
  id?: string
  formId?: string
  weight?: number
  managerSuccessRate?: number
  managerSuccessRateScale?: string
  managerEvaluationNote?: string
  employeeSuccessRate?: number
  employeeSuccessRateScale?: string
  completionNote?: string
  isRequired?: boolean
}

export interface CompetenceEvaluationScaleDto {
  year?: number
  score?: number
  isActive?: boolean
  includedInCalculation?: boolean
  isCommentMandatory?: boolean
  performanceFormOperationYearId?: string
  translations?: Array<CompetenceEvaluationScaleTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceEvaluationScaleTranslatedDto {
  year?: number
  score?: number
  isActive?: boolean
  includedInCalculation?: boolean
  isCommentMandatory?: boolean
  description?: string
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceEvaluationScaleTranslationDto {
  description?: string
  competenceEvaluationScaleId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceEvaluationSelectedScaleIdViewModel {
  performanceFormDetailId?: string
  competenceBehaviorIndicatorId?: string
  selectedCompetenceEvaluationScaleId?: string
  competenceName?: string
}

export interface CompetenceGroupDto {
  isActive?: boolean
  translations?: Array<CompetenceGroupTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceGroupTranslatedDto {
  isActive?: boolean
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceGroupTranslationDto {
  competenceGroupId?: string
  name?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceHeaderEvaluationViewModel {
  competences?: Array<CompetenceEmployeeEvaluationViewModel>
  formId?: string
  formDetailId?: string
  title?: string
  weight?: number
  managerSuccessRate?: number
  managerSuccessRateScale?: string
  managerEvaluationNote?: string
  employeeSuccessRate?: number
  employeeSuccessRateScale?: string
  completionNote?: string
  employeeTag?: PerformanceFormDetailTagTranslatedDto
  managerTag?: PerformanceFormDetailTagTranslatedDto
}

export interface CompetenceLevelDto {
  order?: number
  translations?: Array<CompetenceLevelTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceLevelEditModel {
  competenceLevel?: Array<CompetenceLevelDto>
}

export interface CompetenceLevelTranslatedDto {
  name?: string
  order?: number
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceLevelTranslationDto {
  name?: string
  competenceLevelId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceLightInformationDto {
  competenceTitle?: string
}

export interface CompetenceMatrixDto {
  competenceId?: string
  competenceLevelId?: string
  employeeLevelId?: string
  employeeSubLevelId?: string
  competence?: CompetenceTranslatedDto
  competenceLevel?: CompetenceLevelTranslatedDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceMatrixQueryDto {
  competenceId?: string
  competenceLevelId?: string
  competenceName?: string
  competenceGroupName?: string
  competenceBehaviorIndicators?: Array<CompetenceBehaviorIndicatorQueryDto>
  isCompetenceVisibleToEveryone?: boolean,
  competenceDescription?: string
}

export interface CompetenceTranslatedDto {
  isActive?: boolean
  name?: string
  description?: string
  competenceGroup?: CompetenceGroupTranslatedDto
  competenceBehaviorIndicators?: Array<CompetenceBehaviorIndicatorTranslatedDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceTranslationDto {
  name?: string
  description?: string
  competenceId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CompetenceWeightinessViewModel {
  maximumCompetenceCount?: number
  enteredCompetenceCount?: number
  minimumCompetenceCount?: number
}

export interface ConsumerJobDetailDto {
  consumerJobId?: string
  employeeId?: string
  result?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ConsumerJobDto {
  requesterEmployeeId?: string
  totalCount?: number
  consumerJobType?: ConsumerJobDtoConsumerJobTypeEnum
  consumerJobStatus?: ConsumerJobDtoConsumerJobStatusEnum
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the consumerJobType property.
 */
export type ConsumerJobDtoConsumerJobTypeEnum = '0' | '1' | '2'

/**
 * Enum for the consumerJobStatus property.
 */
export type ConsumerJobDtoConsumerJobStatusEnum = '0' | '1' | '2' | '3' | '4'

export interface CreateTicketRequest {
  title?: string
  categories?: Array<string>
  description?: string
}

export interface CreateTicketResponse {
  successfullyCreated?: boolean
  ticketId?: string
}

export interface CrossCompanyManagerDto {
  employeeId?: string
  employeeCompanyId?: string
  managerId?: string
  managerCompanyId?: string
  isOnlyView?: boolean
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface CurrentPerformanceFormStatusMetricDto {
  type?: CurrentPerformanceFormStatusMetricDtoTypeEnum
  count?: number
}

/**
 * Enum for the type property.
 */
export type CurrentPerformanceFormStatusMetricDtoTypeEnum = '1' | '2' | '3' | '4' | '5' | '6'

export interface CurrentPerformanceFormSummaryInfo {
  maximumDetail?: number
  title?: string
  performanceFormId?: string
  performanceFormDetailId?: string
  competenceAssignmentType?: CurrentPerformanceFormSummaryInfoCompetenceAssignmentTypeEnum
  activeTargetCount?: number
}

/**
 * Enum for the competenceAssignmentType property.
 */
export type CurrentPerformanceFormSummaryInfoCompetenceAssignmentTypeEnum = '0' | '1' | '2' | '3'

export interface CurrentPerformanceFormSummaryViewModel {
  formStatus?: CurrentPerformanceFormSummaryViewModelFormStatusEnum
  competenceWeightiness?: CompetenceWeightinessViewModel
  targetWeightiness?: TargetWeightinessViewModel
  targetPerformanceFormInfos?: Array<CurrentPerformanceFormSummaryInfo>
  competencePerformanceFormInfos?: Array<CurrentPerformanceFormSummaryInfo>
  activePeriodType?: CurrentPerformanceFormSummaryViewModelActivePeriodTypeEnum
}

/**
 * Enum for the formStatus property.
 */
export type CurrentPerformanceFormSummaryViewModelFormStatusEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

/**
 * Enum for the activePeriodType property.
 */
export type CurrentPerformanceFormSummaryViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface DepartmentDto {
  parentDepartmentId?: string
  parentDepartment?: DepartmentDto
  managerPositionId?: string
  managerPosition?: PositionDto
  substituteDepartmentId?: string
  substituteDepartment?: DepartmentDto
  name?: string
  code?: string
  status?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface DepartmentResultDto {
  id?: string
  name?: string
}

export interface DepartmentSearchDto {
  name?: string
  id?: string
}

export interface EducationDto {
  code?: string
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmailDto {
  detail?: string
  employeeId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeApprovalFlowViewModel {
  fullName?: string
  isActiveApproval?: boolean
  approvalOrder?: number
}

export interface EmployeeComparisonGroupBulkRequestDto {
  employeeIds?: Array<string>
  comparisonGroupIds?: Array<string>
  yearParams?: YearParameter
}

export interface EmployeeComparisonGroupDto {
  comparisonGroupId?: string
  employeeId?: string
  year?: number
  yearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeDashboardCompetenceModel {
  competenceName?: string
  competenceId?: string
  categoryName?: string
  commentCount?: number
  competenceDescription?: string
  competenceStatus?: string
}

export interface EmployeeDashboardCompetenceSummaryModel {
  title?: string
  dashboardCompetences?: Array<EmployeeDashboardCompetenceModel>
}

export interface EmployeeDashboardTargetModel {
  targetName?: string
  correlationCount?: number
  targetId?: string
  titleId?: string
  performanceFormDetailKeyResults?: Array<PerformanceFormDetailKeyResultDto>
  commentCount?: number
  targetStartDate?: Date
  targetEndDate?: Date
}

export interface EmployeeDashboardTargetSummaryModel {
  title?: string
  dashboardTargets?: Array<EmployeeDashboardTargetModel>
}

export interface EmployeeDashboardViewModel {
  hasPerformanceForm?: boolean
  performanceFormId?: string
  performanceFormYear?: number
  dashboardTargets?: Array<EmployeeDashboardTargetSummaryModel>
  dashboardCompetences?: Array<EmployeeDashboardCompetenceSummaryModel>
  name?: string
  surname?: string
  fullName?: string
  imageFileName?: string
  email?: string
  department?: string
  position?: string
  employeeNo?: string
  activePeriodType?: EmployeeDashboardViewModelActivePeriodTypeEnum
}

/**
 * Enum for the activePeriodType property.
 */
export type EmployeeDashboardViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface EmployeeDto {
  name?: string
  middleName?: string
  surname?: string
  previousSurname?: string
  birthDate?: Date
  genderId?: string
  educationId?: string
  employeeNo?: string
  departmentId?: string
  locationId?: string
  collarId?: string
  positionId?: string
  positionGroupId?: string
  employeeLevelId?: string
  employeeSubLevelId?: string
  workingStatusId?: string
  functionId?: string
  wageLevelId?: string
  entranceDate?: Date
  locationEntranceDate?: Date
  groupEntranceDate?: Date
  quitDate?: Date
  managerId?: string
  imageFileName?: string
  languageCountryCode?: string
  gender?: GenderDto
  education?: EducationDto
  department?: DepartmentDto
  location?: LocationDto
  collar?: CollarDto
  position?: PositionDto
  positionGroup?: PositionGroupDto
  employeeLevel?: EmployeeLevelDto
  employeeSubLevel?: EmployeeSubLevelDto
  workingStatus?: WorkingStatusDto
  _function?: FunctionDto
  wageLevel?: WageLevelDto
  manager?: EmployeeDto
  phones?: Array<PhoneDto>
  emails?: Array<EmailDto>
  addresses?: Array<AddressDto>
  fullName?: string
  fullNameWithEmployeeNo?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeEvaluationViewModel {
  performanceFormId?: string
  activePeriodType?: EmployeeEvaluationViewModelActivePeriodTypeEnum
  canCurrentUserAbleToSeeEvaluationScore?: boolean
  employeeSuccessRate?: number
  employeeSuccessRateScale?: string
  managerSuccessRate?: number
  calibrationSuccessRate?: string
  managerSuccessRateScale?: string
  targetHeaderEvaluations?: Array<TargetHeaderEvaluationViewModel>
  competenceHeaderEvaluations?: Array<CompetenceHeaderEvaluationViewModel>
  isMyForm?: boolean
  formOwnerFullName?: string
  approvalForViewer?: PerformanceFormPeriodApprovalDto
  approvalUpNext?: PerformanceFormPeriodApprovalDto
  approvalEmployeeFullName?: string
  positionName?: string
  organizationName?: string
  approvalEmployees?: Array<EmployeeApprovalFlowViewModel>
  formTemplateGroupParameter?: PerformanceFormTemplateGroupParameterDto
  isAdminView?: boolean
  isAdmin?: boolean
  showApprovalButtonToSecondManager?: boolean
  year?: number
  performanceFormOperationYearId?: string
}

/**
 * Enum for the activePeriodType property.
 */
export type EmployeeEvaluationViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface EmployeeFilterDto {
  employeeIds?: Array<string>
  // year?: number
  yearParams?: YearParameter
  periodId?: string
  okrPeriodId?: string
}

export interface EmployeeFilterResultDto {
  company?: string
  location?: string
  employeeNo?: string
  name?: string
  surName?: string
  department?: string
  position?: string
  positionGroup?: string
  wageLevel?: string
  collar?: string
  currentYear?: number
  currentYearId?: string
  currentTerm?: string
  currentApproval?: string
  employeeLevelName?: string
  employeeSubLevelName?: string
  firstApprovalEmployee?: string
  secondApprovalEmployee?: string
  thirdApprovalEmployee?: string
  fourthApprovalEmployee?: string
  fifthApprovalEmployee?: string
  currentApprovalEmployeeId?: string
  employeeId?: string
  periodTypeId?: number
  status?: string
  firstApprovalEmployeeNo?: string
  secondApprovalEmployeeNo?: string
  thirdApprovalEmployeeNo?: string
  fourthApprovalEmployeeNo?: string
  fifthApprovalEmployeeNo?: string
  employeeSuccessRate?: number
  managerSuccessRate?: number
}

export interface EmployeeGroupCriteriaDto {
  employeeGroupId?: string
  criteriaType?: EmployeeGroupCriteriaDtoCriteriaTypeEnum
  values?: Array<EmployeeGroupValueDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the criteriaType property.
 */
export type EmployeeGroupCriteriaDtoCriteriaTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6'

export interface EmployeeGroupCriteriaTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeGroupDto {
  isActive?: boolean
  employeeGroupId?: string
  criterias?: Array<EmployeeGroupCriteriaDto>
  translations?: Array<EmployeeGroupTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeGroupTranslatedDto {
  isActive?: boolean
  name?: string
  description?: string
  employeeGroupId?: string
  criterias?: Array<EmployeeGroupCriteriaDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeGroupTranslationDto {
  name?: string
  description?: string
  employeeGroupId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeGroupValueDto {
  employeeGroupId?: string
  employeeGroupCriteriaId?: string
  referenceId?: string
  referenceIntegerId?: number
  referenceDescription?: string
  minimumNumberValue?: number
  maximumNumberValue?: number
  includeSubOrganizations?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeLevelDto {
  status?: boolean
  translations?: Array<EmployeeLevelTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeLevelTranslatedDto {
  name?: string
  status?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeLevelTranslationDto {
  name?: string
  employeeLevelId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeLightDto {
  companyId?: string
  name?: string
  middleName?: string
  surname?: string
  employeeNo?: string
  entranceDate?: Date
  locationEntranceDate?: Date
  groupEntranceDate?: Date
  quitDate?: Date
  managerId?: string
  isTeamManager?: boolean
  fullName?: string
  imageFileName?: string
  departmentId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeLightQueryDto {
  id?: string
  employeeNo?: string
  name?: string
  middleName?: string
  surname?: string
  position?: string
  department?: string
  imageFileName?: string
}

export interface EmployeeNameDto {
  employeeId?: string
  employeeNo?: string
  name?: string
  middleName?: string
  surname?: string
  fullName?: string
  imageFileName?: string
}

export interface EmployeePerformanceSurveyProfileViewModel {
  employeeSuccessRate?: number
  managerSuccessRate?: number
  targetList?: Array<EmployeePerformanceSurveyTargetDefinition>
  unsuccesList?: Array<EmployeePerformanceSurveyTargetDefinition>
  betweenTargetAndMinList?: Array<EmployeePerformanceSurveyTargetDefinition>
  betweenTargetAndMaxList?: Array<EmployeePerformanceSurveyTargetDefinition>
  name?: string
  surname?: string
  fullName?: string
  email?: string
  department?: string
  position?: string
  employeeNo?: string
}

export interface EmployeePerformanceSurveyTargetDefinition {
  targetName?: string
}

export interface EmployeePerformanceViewModel {
  employeeId?: string
  name?: string
  middleName?: string
  surname?: string
  imageFileName?: string
  title?: string
  completedTargetCount?: number
  totalTargetCount?: number
  totalCompetenceCount?: number
  teamMemberCount?: number
  performanceFormId?: string
  oncomingDayText?: string
  actionState?: ActionStateViewModel
  status?: number
  isTeamMember?: boolean
  isCrossCompanyMember?: boolean
  teamMambers?: Array<EmployeeLightDto>
  isEvaluationSurveyCompleted?: boolean
  isShowCompletedEvaluationSurvey?: boolean
  isEvaluationSurveyForEmployeeCompleted?: boolean
  isShowCompletedEvaluationSurveyForEmployee?: boolean
  activePeriodType?: EmployeePerformanceViewModelActivePeriodTypeEnum
  formDetailStatus?: number
}

/**
 * Enum for the activePeriodType property.
 */
export type EmployeePerformanceViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface EmployeePeriodReportDto {
  year?: number
  companyName?: string
  employeeNo?: string
  employeeName?: string
  employeeSurname?: string
  departmentName?: string
  periodTypeName?: string
}

export interface EmployeePeriodsDto {
  count?: number
  activePeriodType?: EmployeePeriodsDtoActivePeriodTypeEnum
}

/**
 * Enum for the activePeriodType property.
 */
export type EmployeePeriodsDtoActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface EmployeeSearchApprovalResultDto {
  id?: string
  approvalType?: EmployeeSearchApprovalTypeResultDto
  approvalEmployee?: EmployeeSearchBaseResultDto
  approvalOrder?: number
  isActiveApproval?: boolean
}

export interface EmployeeSearchApprovalTypeResultDto {
  fixedApprovalType?: EmployeeSearchApprovalTypeResultDtoFixedApprovalTypeEnum
  id?: string
  name?: string
  fixedThreeSixtyRole?: number
  threeSixtyRoleId?: string
}

/**
 * Enum for the fixedApprovalType property.
 */
export type EmployeeSearchApprovalTypeResultDtoFixedApprovalTypeEnum = '0' | '1' | '2' | '3'

export interface EmployeeSearchBaseResultDto {
  id?: string
  name?: string
  fixedThreeSixtyRole?: number
  threeSixtyRoleId?: string
}

export interface EmployeeSearchDto {
  locations?: Array<string>
  employeeNos?: Array<string>
  fullName?: string
  departments?: Array<string>
  includeSubDepartments?: boolean
  positions?: Array<string>
  positionGroups?: Array<string>
  collars?: Array<string>
  workingStatuses?: Array<string>
  employeeLevels?: Array<string>
  employeeSubLevels?: Array<string>
  wageLevels?: Array<string>
  functions?: Array<string>
  educations?: Array<string>
  entranceDateRange?: Array<Date>
  quitDateRange?: Array<Date>
  includeQuitEmployees?: boolean
  onlyQuitEmployees?: boolean
  performancePeriods?: Array<string>
  hasPerformanceForm?: boolean
  performanceFormTemplateGroups?: Array<string>
  performanceFormTemplates?: Array<string>
  currentPerformanceYearParams?: YearParameter
  filterPerformanceYearParams?: YearParameter
  previousPerformanceYearParams?: YearParameter
  comparisonGroupAssignmentYearParams?: YearParameter
  comparisonGroups?: Array<string>
  hasComparisonGroup?: boolean
  threeSixtyGroups?: Array<string>
  hasThreeSixtyGroup?: boolean
  okrTeams?: Array<string>
  hasOkrTeam?: boolean
  approvalFilterMode?: EmployeeSearchDtoApprovalFilterModeEnum
  searchMode?: EmployeeSearchDtoSearchModeEnum
  threeSixtyPeriods?: Array<string>
  threeSixtyPeriodStatuses?: number
  threeSixtyPeriodId?: string
  reportGroup?: number
  threeSixtyGroupId?: string
  minimumBadgeLimit?: number
  maximumBadgeLimit?: number
  badgePeriodId?: string
  badgeCategories?: Array<string>
  hasBadgeCategory?: boolean
  teams?: Array<string>
  hasTeam?: boolean
  userModules?: Array<string>
  hasUserModule?: boolean
  products?: Array<string>
  productCategories?: Array<string>
  orderStatuses?: Array<number>
  orderDateRange?: Array<Date>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  okrPeriodId?: string
}

/**
 * Enum for the approvalFilterMode property.
 */
export type EmployeeSearchDtoApprovalFilterModeEnum = '0' | '1' | '2'

/**
 * Enum for the searchMode property.
 */
export type EmployeeSearchDtoSearchModeEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'

export interface EmployeeSearchOrderResultDto {
  id?: string
  productCategory?: EmployeeSearchBaseResultDto
  product?: EmployeeSearchBaseResultDto
  orderStatusText?: string
  orderStatusId?: number
  orderDateUTC?: Date
  integrationType?: number
  integrationTypeText?: string
}

export interface EmployeeSearchResultDto {
  employeeId?: string
  employeeNo?: string
  name?: string
  surname?: string
  performanceFormId?: string
  // performanceFormYear?: number
  performanceFormYearParams?: YearParameter
  activePeriodName?: string
  activePeriodType?: EmployeeSearchResultDtoActivePeriodTypeEnum
  currentPerformanceFormTemplate?: EmployeeSearchBaseResultDto
  performanceFormTemplateSuggestions?: Array<EmployeeSearchBaseResultDto>
  employeeGroups?: Array<EmployeeSearchBaseResultDto>
  approvals?: Array<EmployeeSearchApprovalResultDto>
  threeSixtyEmployeeApproval?: ThreeSixtyEmployeeApproval
  comparisonGroups?: Array<EmployeeSearchBaseResultDto>
  threeSixtyGroups?: Array<EmployeeSearchBaseResultDto>
  okrTeams?: Array<EmployeeSearchBaseResultDto>
  userModules?: Array<EmployeeSearchBaseResultDto>
  currentApprovalEmployeeNo?: string
  currentApprovalEmployeeFullName?: string
  isEvaluationAnswered?: boolean
  isShowCompletedEvaluationSurvery?: boolean
  isEvaluationAnsweredForEmployee?: boolean
  isShowCompletedEvaluationSurveyForEmployee?: boolean
  position?: EmployeeSearchBaseResultDto
  department?: EmployeeSearchBaseResultDto
  teamMemberCount?: number
  limitOfBadgeUsage?: number
  usedNumberOfBadge?: number
  badgePeriodId?: string
  badgeCategories?: Array<EmployeeSearchBaseResultDto>
  teams?: Array<EmployeeSearchBaseResultDto>
  threeSixtyPeriodName?: string
  threeSixtyPeriodStatus?: EmployeeSearchResultDtoThreeSixtyPeriodStatusEnum
  threeSixtyPeriodId?: string
  selectedThreeSixtyPeriodId?: string
  overallThreeSixtyEvaluationStatus?: EmployeeSearchResultDtoOverallThreeSixtyEvaluationStatusEnum
  orders?: Array<EmployeeSearchOrderResultDto>
  adminBadges?: Array<EmployeeSearchBaseResultDto>
  performanceYearScore?: string
  employeeLevelName?: string
  threeSixtyApprovalId?: string
  threeSixtyApprovalEmployeeId?: string
  managerId?: string
  threeSixtyApprovalFullName?: string
  threeSixtyEmployeeApprovals?: Array<EmployeeSearchApprovalResultDto>
  threeSixtyRoleId?: string
  threeSixtyRoleName?: string
  fixedThreeSixtyRole?: number
  evaluationStatus?: number
  evaluatorEmployeeId?: string
  evaluatorEmployeeNo?: string
  evaluatorEmployeeFullName?: string
  threeSixtyEvaluatorId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  employeeSuccessRate?: number
  managerSuccessRate?: number
  locationName: string
  calibrationSuccessRate?: string
  performanceFormYearId?: string
}

/**
 * Enum for the activePeriodType property.
 */
export type EmployeeSearchResultDtoActivePeriodTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the threeSixtyPeriodStatus property.
 */
export type EmployeeSearchResultDtoThreeSixtyPeriodStatusEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the overallThreeSixtyEvaluationStatus property.
 */
export type EmployeeSearchResultDtoOverallThreeSixtyEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface EmployeeSubLevelDto {
  status?: boolean
  employeeLevelId?: string
  translations?: Array<EmployeeSubLevelTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeSubLevelTranslatedDto {
  name?: string
  status?: boolean
  employeeLevelId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeSubLevelTranslationDto {
  name?: string
  employeeSubLevelId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EmployeeTargetCorrelationViewModel {
  targetName?: string
  performanceFormDetailId?: string
  performanceFormId?: string
  performanceFormTargetCorrelations?: Array<PerformanceFormTargetCorrelationViewModel>
}

export interface EnigmaClientSettings {
  env?: string
  nodEENV?: string
  debuGMODE?: boolean
  basEAPIURL?: string
  identityserveRAUTHORITY?: string
  identityserveRCLIENTID?: string
  identityserveRREDIRECTURI?: string
  identityserveRRESPONSETYPE?: string
  identityserveRSCOPES?: string
  identityserveRPOSTLOGOUTREDIRECTURI?: string
  clienTAPPBASEPATH?: string
  clienTLOGGERENABLED?: boolean
  basEPERFORMANCEAPIURL?: string
  basENOTIFICATIONAPIURL?: string
  basETASKMANAGEMENTAPIURL?: string
  basEDOCUMENTMANAGEMENTAPIURL?: string
  basEREPORTMANAGEMENTAPIURL?: string
  basECLIENTLOGGINGAPIURL?: string
  basETHANXAPIURL?: string
}

export interface EquipOkrObjectivesWithDetailInfoViewModel {
  objectiveId?: string
  isActive?: boolean
  okrCompanyStrategyId?: string
  teamId?: string
  teamName?: string
  targetStartDate?: Date
  targetEndDate?: Date
  purposeName?: string
  purposeDescription?: string
  keyResults?: Array<OkrKeyResultExtendedDto>
}

export interface EvaluationAnswerMultipleChoiceDto {
  evaluationQuestionId?: string
  isActive?: boolean
  displayOrder?: number
  translations?: Array<EvaluationAnswerMultipleChoiceTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationAnswerMultipleChoiceForEmployeeDto {
  evaluationQuestionId?: string
  isActive?: boolean
  displayOrder?: number
  translations?: Array<EvaluationAnswerMultipleChoiceForEmployeeTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationAnswerMultipleChoiceForEmployeeTranslatedDto {
  evaluationQuestionId?: string
  isActive?: boolean
  displayOrder?: number
  answer?: string
  translations?: Array<EvaluationAnswerMultipleChoiceForEmployeeTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationAnswerMultipleChoiceForEmployeeTranslationDto {
  evaluationAnswerMultipleChoiceId?: string
  answer?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationAnswerMultipleChoiceTranslatedDto {
  evaluationQuestionId?: string
  isActive?: boolean
  displayOrder?: number
  answer?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationAnswerMultipleChoiceTranslationDto {
  evaluationAnswerMultipleChoiceId?: string
  answer?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationQuestionDto {
  isActive?: boolean
  displayOrder?: number
  year?: number
  performanceFormOperationYearId?: string
  questionType?: EvaluationQuestionDtoQuestionTypeEnum
  canUseOtherOption?: boolean
  evaluationAnswerMultipleChoices?: Array<EvaluationAnswerMultipleChoiceDto>
  translations?: Array<EvaluationQuestionTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the questionType property.
 */
export type EvaluationQuestionDtoQuestionTypeEnum = '0' | '1' | '2'

export interface EvaluationQuestionForEmployeeDto {
  isActive?: boolean
  displayOrder?: number
  year?: number
  performanceFormOperationYearId?: string
  questionType?: EvaluationQuestionForEmployeeDtoQuestionTypeEnum
  canUseOtherOption?: boolean
  evaluationAnswerMultipleChoices?: Array<EvaluationAnswerMultipleChoiceForEmployeeDto>
  translations?: Array<EvaluationQuestionForEmployeeTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the questionType property.
 */
export type EvaluationQuestionForEmployeeDtoQuestionTypeEnum = '0' | '1' | '2'

export interface EvaluationQuestionForEmployeeTranslatedDto {
  isActive?: boolean
  displayOrder?: number
  year?: number
  performanceFormOperationYearId?: string
  questionType?: EvaluationQuestionForEmployeeTranslatedDtoQuestionTypeEnum
  canUseOtherOption?: boolean
  question?: string
  evaluationAnswerMultipleChoices?: Array<EvaluationAnswerMultipleChoiceForEmployeeTranslatedDto>
  translations?: Array<EvaluationQuestionForEmployeeTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the questionType property.
 */
export type EvaluationQuestionForEmployeeTranslatedDtoQuestionTypeEnum = '0' | '1' | '2'

export interface EvaluationQuestionForEmployeeTranslationDto {
  evaluationQuestionId?: string
  question?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationQuestionTranslatedDto {
  isActive?: boolean
  displayOrder?: number
  year?: number
  performanceFormOperationYearId?: string
  questionType?: EvaluationQuestionTranslatedDtoQuestionTypeEnum
  canUseOtherOption?: boolean
  question?: string
  evaluationAnswerMultipleChoices?: Array<EvaluationAnswerMultipleChoiceTranslatedDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the questionType property.
 */
export type EvaluationQuestionTranslatedDtoQuestionTypeEnum = '0' | '1' | '2'

export interface EvaluationQuestionTranslationDto {
  evaluationQuestionId?: string
  question?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface EvaluationQuestionTypeDto {
  name?: string
  id?: EvaluationQuestionTypeDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the id property.
 */
export type EvaluationQuestionTypeDtoIdEnum = '0' | '1' | '2'

export interface ExistenceTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface FunctionDto {
  code?: string
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface GenderDto {
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface GetChangedActiveApproversInputDto {
  yearParams?: YearParameter
  isIgnoredChange?: boolean
}

export interface GetQuittedActiveApproversInputDto {
  yearParams?: YearParameter
}

export interface GetThreeSixtyDashboardRequest {
  employeeId?: string
}

export interface GetThreeSixtyEvaluatingEmployeesRequest {
  periodId?: string
  employeeId?: string
}

export interface GetThreeSixtyEvaluationReportRequest {
  employeeId?: string
  threeSixtyPeriodId?: string
}

export interface GetThreeSixtyEvaluationRequest {
  threeSixtyEvaluatorId?: string
}

export interface GetThreeSixtyEvaluatorsForEditRequest {
  periodId?: string
  employeeId?: string
}

export interface GetThreeSixtyEvaluatorsRequest {
  periodId?: string
  employeeId?: string
}

export interface GetThreeSixtyGroupStatusRequest {
  threeSixtyGroupId?: string
}

export interface GetThreeSixtyRoleStatusRequest {
  threeSixtyRoleId?: string
}

export interface GetThreeSixtyWaitingApprovalsRequest {
  periodId?: string
  employeeId?: string
}

export interface GroupDto {
  groupName?: string
  groupKey?: string
  companyId?: string
  userGroups?: Array<UserGroupDto>
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface JobItem {
  cronJobName?: string
  jobName?: string
  cronExpression?: string
  containerImage?: string
  executionParameters?: string
}

export interface KeyResultReportDto {
  name?: string
  surName?: string
  targetName?: string
  targetStartDate?: string
  targetEndDate?: string
  keyResultName?: string
  keyResultDate?: string
  description?: string
}

export interface LeftEmployeeDto {
  id?: string
  companyId?: string
  managerId?: string
  name?: string
  surname?: string
  employeeNo?: string
  quitDate?: Date
}

export interface ListCompetenceBehaviorsByFormQuery {
  competenceId?: string
  competenceLevelId?: string
  competenceName?: string
  competenceBehaviorIndicatorId?: string
  behaviorIdicatorName?: string
  performanceFormDetailId?: string
  employeeCompetenceEvaluationScaleId?: string
  managerCompetenceEvaluationScaleId?: string
}

export interface Location {
  name?: string
  shortName?: string
  code?: string
  address?: string
  postCode?: string
  isActive?: boolean
  companyId?: string
  company?: Company
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface LocationDto {
  code?: string
  name?: string
  locationRiskLevelId?: string
  isActive?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface LockedEmployeeDto {
  userName?: string
  isLocked?: boolean
  failedLoginCount?: number
  passwordResetRequestCount?: number
}

export interface MandatoryTargetEvaluationEmployee {
  employeeNo?: string
  fullName?: string
}

export interface MandatoryTargetEvaluationResult {
  results?: Array<MandatoryTargetEvaluationResultModel>
  hasWarning?: boolean
}

export interface MandatoryTargetEvaluationResultDto {
  id?: string
  performanceFormTemplateId?: string
  performanceFormTemplateDetailId?: string
  targetUnit?: MandatoryTargetEvaluationResultDtoTargetUnitEnum
  targetValueDate?: Date
  targetValuePercent?: number
  targetValueNumber?: number
  targetValueText?: string
  targetValueDateResult?: Date
  targetValuePercentResult?: number
  targetValueNumberResult?: number
  targetValueTextResult?: number
  targetName?: string
}

/**
 * Enum for the targetUnit property.
 */
export type MandatoryTargetEvaluationResultDtoTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

export interface MandatoryTargetEvaluationResultModel {
  errorTitle?: string
  errorContent?: string
  mandatoryTargetEvaluationEmployees?: Array<MandatoryTargetEvaluationEmployee>
}

export interface MandatoryTargetEvaluationResultRequestDto {
  yearParams?: YearParameter
  performanceFormTemplateGroupId?: string
  performanceFormTemplateId?: string
}

export interface OkrCompanyStrategyDto {
  isActive?: boolean
  okrCompanyStrategyRelation?: Array<OkrCompanyStrategyRelationDto>
  translations?: Array<OkrCompanyStrategyTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrCompanyStrategyRelationDto {
  okrCompanyStrategyId?: string
  relatedType?: OkrCompanyStrategyRelationDtoRelatedTypeEnum
  okrTeamId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the relatedType property.
 */
export type OkrCompanyStrategyRelationDtoRelatedTypeEnum = '0' | '1' | '2'

export interface OkrCompanyStrategyTranslatedDto {
  isActive?: boolean
  name?: string
  description?: string
  relations?: Array<OkrCompanyStrategyRelationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrCompanyStrategyTranslationDto {
  name?: string
  description?: string
  okrCompanyStrategyId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrGetTeamEmployeeDto {
  employeeId?: string
  teamId?: string
  firstName?: string
  lastName?: string
  fullName?: string
  imageFileName?: string
}

export interface OkrKeyResultAssignedEmployeeDto {
  employeeId?: string
  okrKeyResultId?: string
  createdDateUTC?: Date
  okrKeyResultAssignedId?: string
  fullName?: string
  imageFileName?: string
  isKeyResultAssignedCurrentUser?: boolean
  companyId?: string
  id?: string
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrKeyResultAssignedEmployeeExtendedDto {
  fullName?: string
  imageFileName?: string
  employeeId?: string
}

export interface OkrKeyResultAssignedEmployeeSummaryDto {
  okrKeyResultId?: string
  fullName?: string
  imageFileName?: string
  employeeId?: string
  isKeyResultAssignedCurrentUser?: boolean
}

export interface OkrKeyResultDto {
  keyResultName?: string
  keyResultDescription?: string
  okrObjectiveId?: string
  okrKeyResultAssignedEmployees?: Array<OkrKeyResultAssignedEmployeeDto>
  keyResultUnit?: OkrKeyResultDtoKeyResultUnitEnum
  keyResultWeight?: number
  currentValueDate?: Date
  targetValueDate?: Date
  currentValuePercent?: number
  targetValuePercent?: number
  currentValueNumber?: number
  targetValueNumber?: number
  currentValueText?: string
  targetValueText?: string
  progress?: number
  progressData?: number
  progressDescription?: string
  okrKeyResultTasks?: Array<OkrKeyResultTaskDto>
  keyResultStatus?: OkrKeyResultDtoKeyResultStatusEnum
  keyResultStatusNote?: string
  keyResultStatusText?: string
  okrKeyResultEvaluation?: OkrKeyResultEvaluationDto
  keyResultStatusDescription?: string
  keyResultStatusModifier?: string
  createdDateUTC?: Date
  okrKeyResultId?: string
  companyId?: string
  id?: string
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the keyResultUnit property.
 */
export type OkrKeyResultDtoKeyResultUnitEnum = '0' | '1' | '2' | '3' | '4'

export interface OkrKeyResultExtendedDto {
  keyResultId?: string
  keyResultName?: string
  keyResultDescription?: string
  objectiveId?: string
  okrKeyResultAssignedEmployees?: Array<OkrKeyResultAssignedEmployeeExtendedDto>
}

export interface OkrKeyResultEvaluationDto {
  id?: string
  okrKeyResultId?: string
  okrKeyResultEvaluationNote?: string
  okrKeyResultEvaluationValue?: number
}

export interface OkrKeyResultSummaryDto {
  keyResultId?: string
  okrObjectiveId?: string
  keyResultName?: string
  keyResultDescription?: string
  keyResultWeight?: number
  keyResultUnit?: OkrKeyResultSummaryDtoKeyResultUnitEnum
  keyResultStatus?: OkrKeyResultDtoKeyResultStatusEnum
  keyResultStatusNote?: string
  okrKeyResultEvaluation?: OkrKeyResultEvaluationDto
  currentValueDate?: Date
  targetValueDate?: Date
  currentValuePercent?: number
  targetValuePercent?: number
  currentValueNumber?: number
  targetValueNumber?: number
  currentValueText?: string
  targetValueText?: string
  okrKeyResultAssignedEmployees?: Array<OkrKeyResultAssignedEmployeeSummaryDto>
  progress?: number
  progressData?: number
  progressDescription?: string
  okrKeyResultTasks?: Array<OkrKeyResultTaskDto>
  createdDateUTC?: Date
}

/**
 * Enum for the keyResultUnit property.
 */
export type OkrKeyResultSummaryDtoKeyResultUnitEnum = '0' | '1' | '2' | '3' | '4'

export interface OkrKeyResultTaskDto {
  okrKeyResultTaskName?: string
  okrKeyResultId?: string
  isCompleted?: boolean
  okrKeyResultTaskId?: string
  createdDateUTC?: Date
  companyId?: string
  id?: string
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrKeyResultUnitDto {
  translations?: Array<OkrKeyResultUnitTranslationDto>
  id?: OkrKeyResultUnitDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the id property.
 */
export type OkrKeyResultUnitDtoIdEnum = '0' | '1' | '2' | '3' | '4'

export interface OkrKeyResultUnitTranslatedDto {
  name?: string
  keyResultUnits?: OkrKeyResultUnitTranslatedDtoKeyResultUnitsEnum
  id?: OkrKeyResultUnitTranslatedDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the keyResultUnits property.
 */
export type OkrKeyResultUnitTranslatedDtoKeyResultUnitsEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the id property.
 */
export type OkrKeyResultUnitTranslatedDtoIdEnum = '0' | '1' | '2' | '3' | '4'

export interface OkrKeyResultUnitTranslationDto {
  keyResultUnits?: OkrKeyResultUnitTranslationDtoKeyResultUnitsEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the keyResultUnits property.
 */
export type OkrKeyResultUnitTranslationDtoKeyResultUnitsEnum = '0' | '1' | '2' | '3' | '4'

export interface OkrMyEquipObjectivesWithDetailInfoViewModel {
  objectiveId?: string
  isActive?: boolean
  okrCompanyStrategyId?: string
  targetStartDate?: Date
  targetEndDate?: Date
  purposeName?: string
  purposeDescription?: string
  progressScore?: number
  keyResults?: Array<OkrKeyResultExtendedDto>
}

export interface OkrObjectiveDto {
  isActive?: boolean
  //isEquipObjective?: boolean
  objectiveType?: number
  okrCompanyStrategyId?: string
  teamId?: string
  employeeId?: string
  targetStartDate?: Date
  targetEndDate?: Date
  purposeName?: string
  purposeDescription?: string
  okrKeyResultDtos?: Array<OkrKeyResultDto>
  createdDateUTC?: Date
  progressScore?: number
  objectiveId?: string
  teamName?: string
  companyStrategyName?: string
  companyStrategyDescription?: string
  departmentId?: string
  departmentName?: string
  companyId?: string
  id?: string
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  hideInformationField: boolean
  showLastFifteenDaysInformation: boolean
  okrPeriodId?: string
  isOkrVisibleInTree?: boolean
}

export interface OkrObjectiveTeamEmployeeDto {
  teamId?: string
  teamName?: string
}

export interface OkrObjectiveWithKeyResultAndEmployeeSummaryViewModel {
  objectiveId?: string
  isActive?: boolean
  teamId?: string
  teamName?: string
  targetStartDate?: Date
  targetEndDate?: Date
  purposeName?: string
  purposeDescription?: string
  okrCompanyStrategyId?: string
  companyStrategyName?: string
  companyStrategyDescription?: string
  okrKeyResultDtos?: Array<OkrKeyResultSummaryDto>
}

export interface OkrObjectivesInManager {
  employeeId?: string
  employeeFullName?: string
  imageFileName?: string
  positionTitle?: string
  totalKeyResultCount?: number
  objectives?: Array<OkrObjectivesWithDetailInfoViewModel>
}

export interface OkrObjectivesInTeam {
  teamId?: string
  teamName?: string
  objectives?: Array<OkrObjectivesWithDetailInfoViewModel>
}

export interface OkrObjectivesInTeamForManager {
  managerId?: string
  objectives?: Array<EquipOkrObjectivesWithDetailInfoViewModel>
}

export interface OkrObjectivesWithDetail {
  objectivesInTeams?: Array<OkrObjectivesInTeam>
}

export interface OkrObjectivesWithDetailForManager {
  objectivesInTeams?: Array<OkrObjectivesInTeamForManager>
}

export interface OkrObjectivesWithDetailInfoViewModel {
  objectiveId?: string
  isActive?: boolean
  okrCompanyStrategyId?: string
  teamId?: string
  teamName?: string
  targetStartDate?: Date
  targetEndDate?: Date
  purposeName?: string
  purposeDescription?: string
  keyResults?: Array<OkrKeyResultExtendedDto>
}

export interface OkrTeamDto {
  isActive?: boolean
  teamResponsibleEmployeeId?: string
  translations?: Array<OkrTeamTranslationDto>
  teamEmployees?: Array<OkrTeamEmployeeDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrTeamEmployeeBulkRequestDto {
  employeeIds?: Array<string>
  teamIds?: Array<string>
}

export interface OkrTeamEmployeeDto {
  teamId?: string
  employeeId?: string
  team?: OkrTeamDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrTeamEmployeeListBulkRequestDto {
  employeeIds?: Array<string>
}

export interface OkrTeamEmployeeQueryDto {
  employeeId?: string
  teamId?: string
  teamName?: string
}

export interface OkrTeamTranslatedDto {
  isActive?: boolean
  name?: string
  translations?: Array<OkrTeamTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrTeamTranslationDto {
  name?: string
  teamId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ParameterDataTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ParameterDto {
  parameterType?: ParameterDtoParameterTypeEnum
  parameterDataType?: ParameterDtoParameterDataTypeEnum
  translations?: Array<ParameterTranslationDto>
  id?: ParameterDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the parameterType property.
 */
export type ParameterDtoParameterTypeEnum = '0' | '1'

/**
 * Enum for the parameterDataType property.
 */
export type ParameterDtoParameterDataTypeEnum = '0' | '1' | '2'

/**
 * Enum for the id property.
 */
export type ParameterDtoIdEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'

export interface ParameterTranslatedDto {
  name?: string
  parameter?: ParameterTranslatedDtoParameterEnum
  id?: ParameterTranslatedDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the parameter property.
 */
export type ParameterTranslatedDtoParameterEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'

/**
 * Enum for the id property.
 */
export type ParameterTranslatedDtoIdEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'

export interface ParameterTranslationDto {
  parameter?: ParameterTranslationDtoParameterEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the parameter property.
 */
export type ParameterTranslationDtoParameterEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'

export interface ParameterTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceApprovalFlowDetailDto {
  order?: number
  isAuthToEvaluate?: boolean
  performanceApprovalFlowId?: string
  approvalTypeId?: string
  approvalType?: PerformanceApprovalTypeDto
  returnApprovalTypeId?: string
  returnApprovalType?: PerformanceApprovalTypeDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceApprovalFlowDto {
  isActive?: boolean
  performanceApprovalFlowDetails?: Array<PerformanceApprovalFlowDetailDto>
  translations?: Array<PerformanceApprovalFlowTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceApprovalFlowTranslatedDto {
  isActive?: boolean
  name?: string
  performanceApprovalFlowDetails?: Array<PerformanceApprovalFlowDetailDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceApprovalFlowTranslationDto {
  performanceApprovalFlowId?: string
  name?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceApprovalTypeDto {
  isActive?: boolean
  fixedApprovalType?: PerformanceApprovalTypeDtoFixedApprovalTypeEnum
  translations?: Array<PerformanceApprovalTypeTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the fixedApprovalType property.
 */
export type PerformanceApprovalTypeDtoFixedApprovalTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceApprovalTypeTranslatedDto {
  isActive?: boolean
  name?: string
  fixedApprovalType?: PerformanceApprovalTypeTranslatedDtoFixedApprovalTypeEnum
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the fixedApprovalType property.
 */
export type PerformanceApprovalTypeTranslatedDtoFixedApprovalTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceApprovalTypeTranslationDto {
  performanceApprovalTypeId?: string
  name?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceEvaluationScaleDto {
  year?: number
  minimumValue?: number
  maximumValue?: number
  translations?: Array<PerformanceEvaluationScaleTranslationDto>
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceEvaluationScaleTranslatedDto {
  description?: string
  year?: number
  minimumValue?: number
  maximumValue?: number
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceEvaluationScaleTranslationDto {
  performanceEvaluationScaleId?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormApprovalStatusDto {
  translations?: Array<PerformanceFormApprovalStatusTranslationDto>
  id?: PerformanceFormApprovalStatusDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the id property.
 */
export type PerformanceFormApprovalStatusDtoIdEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

export interface PerformanceFormApprovalStatusTranslatedDto {
  name?: string
  performanceFormApprovalStatus?: PerformanceFormApprovalStatusTranslatedDtoPerformanceFormApprovalStatusEnum
  id?: PerformanceFormApprovalStatusTranslatedDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the performanceFormApprovalStatus property.
 */
export type PerformanceFormApprovalStatusTranslatedDtoPerformanceFormApprovalStatusEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

/**
 * Enum for the id property.
 */
export type PerformanceFormApprovalStatusTranslatedDtoIdEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

export interface PerformanceFormApprovalStatusTranslationDto {
  performanceFormApprovalStatus?: PerformanceFormApprovalStatusTranslationDtoPerformanceFormApprovalStatusEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the performanceFormApprovalStatus property.
 */
export type PerformanceFormApprovalStatusTranslationDtoPerformanceFormApprovalStatusEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

export interface PerformanceFormCompetenceGroupQueryDto {
  competences?: Array<PerformanceFormCompetenceQueryDto>
  competenceGroupId?: string
  competenceGroupName?: string
}

export interface PerformanceFormCompetenceQueryDto {
  performanceFormDetailId?: string
  employeeId?: string
  performanceFormYear?: number
  isLocked?: boolean
  competenceId?: string
  competenceName?: string
  competenceDescription?: string
  competenceGroupId?: string
  competenceGroupName?: string
  commentCount?: number
  commentRequestedEmployees?: string
  competenceBehaviors?: Array<ListCompetenceBehaviorsByFormQuery>
  isCompetenceVisibleToEveryone?: boolean
}

export interface CompetenceVisibleDto {
  performanceFormDetailId?: string
  isCompetenceVisibleToEveryone?: boolean
}

export interface PerformanceFormDetailCalculateRequestDto {
  performanceFormDetailId?: string
  actualNumber?: number
  actualPercent?: number
  actualText?: string
  actualDate?: Date
}

export interface PerformanceFormDetailCompanyPriorityDto {
  performanceFormDetailId?: string
  performanceFormId?: string
  companyPrioritiesLibraryId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailCompetenceEvaluationDto {
  performanceFormId?: string
  performanceFormDetailId?: string
  competenceBehaviorIndicatorId?: string
  employeeCompetenceEvaluationScaleId?: string
  managerCompetenceEvaluationScaleId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailCompletionRequestDto {
  performanceFormDetailId?: string
  completionDate?: Date
  completionNote?: string
  actualNumber?: number
  actualPercent?: number
  actualText?: string
  actualDate?: Date
  calculateOnly?: boolean
  directEmployeeSuccessRate?: number
}

export interface PerformanceFormDetailDisplayViewModel {
  isMyForm?: boolean
  isAdminView?: boolean
  formOwnerFullName?: string
  formOwnerEmployeeId?: string
  original?: PerformanceFormDetailDto
  revision?: PerformanceFormDetailDto
  approvalForViewer?: PerformanceFormPeriodApprovalDto
  performanceFormTemplateGroupParameter?: PerformanceFormTemplateGroupParameterDto
  activePeriodType?: PerformanceFormDetailDisplayViewModelActivePeriodTypeEnum
  commentRequestedEmployees?: string
  correlatedTarget?: Array<TargetCorrelationViewModel>
  previousNextTarget?: PreviousNextTargetViewModel
}

export interface PreviousNextTargetViewModel {
  orderInformation?: TargetOrderInformationViewModel
  isPreviousTargetVisible?: boolean
  isNextTargetVisible?: boolean
  previousTargetId?: string
  nextTargetId?: string
}

export interface TargetOrderInformationViewModel {
  currentTargetOrder: number;
  targetCount: number;
}

/**
 * Enum for the activePeriodType property.
 */
export type PerformanceFormDetailDisplayViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceFormDetailDto {
  performanceFormId?: string
  performanceForm?: PerformanceFormDto
  title?: string
  titleDescription?: string
  targetName?: string
  targetDescription?: string
  parentPerformanceFormDetailId?: string
  parentPerformanceFormDetail?: PerformanceFormDetailDto
  childPerformanceFormDetails?: Array<PerformanceFormDetailDto>
  performanceFormDetailKeyResults?: Array<PerformanceFormDetailKeyResultDto>
  performanceFormTargetCorrelations?: Array<PerformanceFormTargetCorrelationDto>
  performanceFormTemplateDetailId?: string
  formDetailType?: PerformanceFormDetailDtoFormDetailTypeEnum
  childFormDetailType?: PerformanceFormDetailDtoChildFormDetailTypeEnum
  formDetailStatus?: PerformanceFormDetailDtoFormDetailStatusEnum
  isActive?: boolean
  displayOrder?: number
  targetStartDate?: Date
  targetEndDate?: Date
  isTargetVisibleToEveryone?: boolean
  weight?: number
  minimumWeight?: number
  maximumWeight?: number
  scoringType?: PerformanceFormDetailDtoScoringTypeEnum
  minimumTargetFromCatalog?: number
  maximumTargetFromCatalog?: number
  minimumDetail?: number
  maximumDetail?: number
  maximumTargetTotal?: number
  targetAssignmentType?: PerformanceFormDetailDtoTargetAssignmentTypeEnum
  targetUnit?: PerformanceFormDetailDtoTargetUnitEnum
  targetValueMinimumDate?: Date
  targetValueDate?: Date
  targetValueMaximumDate?: Date
  targetValueMinimumPercent?: number
  targetValuePercent?: number
  targetValueMaximumPercent?: number
  targetValueMinimumNumber?: number
  targetValueNumber?: number
  targetValueMaximumNumber?: number
  targetValueMinimumText?: string
  targetValueText?: string
  targetValueMaximumText?: string
  competenceAssignmentType?: PerformanceFormDetailDtoCompetenceAssignmentTypeEnum
  templateDetailUnitPermissionType?: PerformanceFormDetailDtoTemplateDetailUnitPermissionTypeEnum
  templateDetailWeightPermissionType?: PerformanceFormDetailDtoTemplateDetailWeightPermissionTypeEnum
  templateDetailMinimumMaximumScorePermissionType?: PerformanceFormDetailDtoTemplateDetailMinimumMaximumScorePermissionTypeEnum
  performanceFormDetailResult?: PerformanceFormDetailResultDto
  performanceFormDetailCompetenceEvaluations?: Array<PerformanceFormDetailCompetenceEvaluationDto>
  competenceId?: string
  competence?: CompetenceTranslatedDto
  aiTargetOfferId?: string
  performanceFormDetailCompanyPriorities?: Array<PerformanceFormDetailCompanyPriorityDto>
  correlatedPerformanceFormDetailId?: string
  createdForRevision?: boolean
  extraFieldId1?: string
  extraFieldId2?: string
  extraFieldId3?: string
  extraFieldId4?: string
  evaluationResultByAdmin?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  isCompetenceVisibleToEveryone?: boolean
  targetCorrelationRequestList?: Array<TargetCorrelationRequest>
  isNew?: boolean
}

/**
 * Enum for the formDetailType property.
 */
export type PerformanceFormDetailDtoFormDetailTypeEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the childFormDetailType property.
 */
export type PerformanceFormDetailDtoChildFormDetailTypeEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the formDetailStatus property.
 */
export type PerformanceFormDetailDtoFormDetailStatusEnum = '0' | '1' | '2'

/**
 * Enum for the scoringType property.
 */
export type PerformanceFormDetailDtoScoringTypeEnum = '0' | '1' | '2'

/**
 * Enum for the targetAssignmentType property.
 */
export type PerformanceFormDetailDtoTargetAssignmentTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the targetUnit property.
 */
export type PerformanceFormDetailDtoTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the competenceAssignmentType property.
 */
export type PerformanceFormDetailDtoCompetenceAssignmentTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the templateDetailUnitPermissionType property.
 */
export type PerformanceFormDetailDtoTemplateDetailUnitPermissionTypeEnum = '0' | '1'

/**
 * Enum for the templateDetailWeightPermissionType property.
 */
export type PerformanceFormDetailDtoTemplateDetailWeightPermissionTypeEnum = '0' | '1'

/**
 * Enum for the templateDetailMinimumMaximumScorePermissionType property.
 */
export type PerformanceFormDetailDtoTemplateDetailMinimumMaximumScorePermissionTypeEnum = '0' | '1'

export interface PerformanceFormDetailEditViewModel {
  isMyForm?: boolean
  formOwnerFullName?: string
  formOwnerEmployeeId?: string
  original?: PerformanceFormDetailDto
  revision?: PerformanceFormDetailDto
  performanceFormTemplateGroupParameter?: PerformanceFormTemplateGroupParameterDto
  activePeriodType?: PerformanceFormDetailEditViewModelActivePeriodTypeEnum
  correlatedTargetByEmployee?: string
  rejectionNote?: string
  isAdminView?: boolean
}

/**
 * Enum for the activePeriodType property.
 */
export type PerformanceFormDetailEditViewModelActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceFormDetailEvaluationRequestDto {
  performanceFormDetailId?: string
  managerEvaluationNote?: string
  managerSuccessRate?: number
}

export interface PerformanceFormDetailExtraFieldDto {
  code?: string
  level?: number
  parentFieldId?: string
  year?: number
  translations?: Array<PerformanceFormDetailExtraFieldTranslationDto>
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailExtraFieldTranslatedDto {
  name?: string
  code?: string
  level?: number
  parentFieldId?: string
  year?: number
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailExtraFieldTranslationDto {
  name?: string
  performanceFormDetailExtraFieldId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailKeyResultDto {
  name?: string
  description?: string
  keyResultDate?: Date
  performanceFormDetailId?: string
  status?: PerformanceFormDetailKeyResultDtoStatusEnum
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the status property.
 */
export type PerformanceFormDetailKeyResultDtoStatusEnum = '0' | '1' | '2' | '3' | '4' | '5'

export interface PerformanceFormDetailKeyResultStatusDto {
  translations?: Array<PerformanceFormDetailKeyResultStatusTranslationDto>
  id?: PerformanceFormDetailKeyResultStatusDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the id property.
 */
export type PerformanceFormDetailKeyResultStatusDtoIdEnum = '0' | '1' | '2' | '3' | '4' | '5'

export interface PerformanceFormDetailKeyResultStatusTranslatedDto {
  name?: string
  keyResultStatus?: PerformanceFormDetailKeyResultStatusTranslatedDtoKeyResultStatusEnum
  id?: PerformanceFormDetailKeyResultStatusTranslatedDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the keyResultStatus property.
 */
export type PerformanceFormDetailKeyResultStatusTranslatedDtoKeyResultStatusEnum = '0' | '1' | '2' | '3' | '4' | '5'

/**
 * Enum for the id property.
 */
export type PerformanceFormDetailKeyResultStatusTranslatedDtoIdEnum = '0' | '1' | '2' | '3' | '4' | '5'

export interface PerformanceFormDetailKeyResultStatusTranslationDto {
  keyResultStatus?: PerformanceFormDetailKeyResultStatusTranslationDtoKeyResultStatusEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the keyResultStatus property.
 */
export type PerformanceFormDetailKeyResultStatusTranslationDtoKeyResultStatusEnum = '0' | '1' | '2' | '3' | '4' | '5'

export interface PerformanceFormDetailResultDto {
  performanceFormId?: string
  performanceFormDetailId?: string
  targetStatusNote?: string
  completionDate?: Date
  completionNote?: string
  actualNumber?: number
  actualPercent?: number
  actualText?: string
  actualDate?: Date
  employeeSuccessRate?: number
  managerSuccessRate?: number
  managerEvaluationNote?: string
  employeeTagId?: string
  managerTagId?: string
  employeeTag?: PerformanceFormDetailTagTranslatedDto
  managerTag?: PerformanceFormDetailTagTranslatedDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailSaveTagRequestDto {
  performanceFormId?: string
  performanceFormDetailId?: string
  employeeTag?: PerformanceFormDetailTagTranslatedDto
  managerTag?: PerformanceFormDetailTagTranslatedDto
}

export interface PerformanceFormDetailTagDto {
  year?: number
  isActive?: boolean
  performanceFormOperationYearId?: string
  translations?: Array<PerformanceFormDetailTagTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailTagTranslatedDto {
  year?: number
  isActive?: boolean
  name?: string
  translations?: Array<PerformanceFormDetailTagTranslationDto>
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailTagTranslationDto {
  name?: string
  performanceFormDetailTagId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDetailTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormDto {
  performanceFormTemplateId?: string
  performanceFormTemplateGroupId?: string
  employeeId?: string
  employee?: EmployeeDto
  year?: number
  activePeriodId?: string
  activePeriodType?: PerformanceFormDtoActivePeriodTypeEnum
  performanceFormOperationYearId?: string
  employeeSuccessRate?: number
  managerSuccessRate?: number
  periods?: Array<PerformanceFormPeriodDto>
  performanceFormDetails?: Array<PerformanceFormDetailDto>
  firstLevelPerformanceFormDetails?: Array<PerformanceFormDetailDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  previousEmployee?: EmployeeNavigationDto
  nextEmployee?: EmployeeNavigationDto
  aiTargetOfferId?: string
}

export interface EmployeeNavigationDto {
  employeeId?: string
  formId?: string
  fullName?: string
}

/**
 * Enum for the activePeriodType property.
 */
export type PerformanceFormDtoActivePeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceFormEditViewModel {
  isMyForm?: boolean
  formOwnerFullName?: string
  approvalForViewer?: PerformanceFormPeriodApprovalDto
  approvalUpNext?: PerformanceFormPeriodApprovalDto
  performanceFormTemplateGroupParameter?: PerformanceFormTemplateGroupParameterDto
  original?: PerformanceFormDto
  revision?: PerformanceFormDto
  approvalEmployeeFullName?: string
  approvalEmployees?: Array<EmployeeApprovalFlowViewModel>
  isAdminView?: boolean
  periodNameTranslated?: string
  organizationName?: string
  positionName?: string
  isCrossCompanyProcess?: boolean
  previousEmployee?: EmployeeNavigationDto
  nextEmployee?: EmployeeNavigationDto
}

export interface PerformanceFormEmployeeDto {
  employeeId?: string
  employee?: EmployeeDto
  performanceFormTemplateId?: string
  performanceFormTemplate?: PerformanceFormTemplateDto
  yearParams?: YearParameter
  companyId?: string
  performanceFormTemplateGroupId?: string
  performanceFormId?: string
  employeeFullName?: string
}

export interface PerformanceFormEmployeeJobDto {
  employeeId?: string
  performanceFormTemplateId?: string
  yearParams?: YearParameter
  companyId?: string
  performanceFormTemplateGroupId?: string
  performanceFormId?: string
}

export interface PerformanceFormEvaluationAnswerBulkRequestDto {
  answers?: Array<PerformanceFormEvaluationAnswerDto>
}

export interface PerformanceFormEvaluationAnswerDto {
  performanceFormId?: string
  evaluationQuestionId?: string
  evaluationAnswerMultipleChoiceId?: string
  evaluationAnswerMultipleChoiceOther?: string
  freeTextAnswer?: string
  answeredEmployeeId?: string
  evaluationQuestion?: EvaluationQuestionDto
  evaluationAnswerMultipleChoice?: EvaluationAnswerMultipleChoiceDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormEvaluationAnswerForEmployeeBulkRequestDto {
  answers?: Array<PerformanceFormEvaluationAnswerForEmployeeDto>
}

export interface PerformanceFormEvaluationAnswerForEmployeeDto {
  performanceFormId?: string
  evaluationQuestionId?: string
  evaluationAnswerMultipleChoiceId?: string
  evaluationAnswerMultipleChoiceOther?: string
  freeTextAnswer?: string
  answeredEmployeeId?: string
  evaluationQuestionForEmployee?: EvaluationQuestionForEmployeeDto
  evaluationAnswerMultipleChoiceForEmployee?: EvaluationAnswerMultipleChoiceForEmployeeDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormEvaluationStartErrorDto {
  errorTitle?: string
  errorContent?: string
  resultType?: PerformanceFormEvaluationStartErrorDtoResultTypeEnum
}

/**
 * Enum for the resultType property.
 */
export type PerformanceFormEvaluationStartErrorDtoResultTypeEnum = '1' | '2' | '3'

export interface PerformanceFormEvaluationStartResultViewModel {
  employeeId?: string
  employeeFullName?: string
  hasError?: boolean
  updatedPeriodType?: PerformanceFormEvaluationStartResultViewModelUpdatedPeriodTypeEnum
  updatedPeriodName?: string
  error?: PerformanceFormEvaluationStartErrorDto
  performanceYear?: number
  performanceFormId?: string
}

/**
 * Enum for the updatedPeriodType property.
 */
export type PerformanceFormEvaluationStartResultViewModelUpdatedPeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceFormLightDto {
  performanceFormTemplateId?: string
  performanceFormTemplateGroupId?: string
  employeeId?: string
  year?: number
  employee?: EmployeeLightDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormMandatoryTargetResultDto {
  performanceFormTemplateId?: string
  performanceFormTemplateDetailId?: string
  targetUnit?: PerformanceFormMandatoryTargetResultDtoTargetUnitEnum
  targetValueDate?: Date
  targetValueDateResult?: Date
  targetValuePercent?: number
  targetValuePercentResult?: number
  targetValueNumber?: number
  targetValueNumberResult?: number
  targetValueText?: string
  targetValueTextResult?: number
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the targetUnit property.
 */
export type PerformanceFormMandatoryTargetResultDtoTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

export interface DisciplineDto {
  translations?: Array<DisciplineTranslationDto>
  employeeId?: string
  disciplineEventCreationDate?: Date
  actionTakenForDisciplinaryEventDate?: Date
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}


export interface DisciplineLightDto {
  disciplineEventCreationDate?: string
  actionTakenForDisciplinaryEventDate?: string
  mainDisciplinaryReason?: string
  actionTakenForDisciplinaryEventDescription?: string
  actionDescription?: string
  disciplinaryEventType?: string
  code?: string
  employeeId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface DisciplineTranslationDto {
  mainDisciplinaryReason?: string
  actionTakenForDisciplinaryEventDescription?: string
  actionDescription?: string
  disciplinaryEventType?: string
  languageCountryCode?: string
  disciplineId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface DisciplineTranslatedDto {
  mainDisciplinaryReason?: string
  actionTakenForDisciplinaryEventDescription?: string
  actionDescription?: string
  disciplinaryEventType?: string
  code?: string
  disciplineEventCreationDate?: Date
  actionTakenForDisciplinaryEventDate?: Date
  disciplineId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}


export interface PerformanceFormOperationYearDto {
  translations?: Array<PerformanceFormOperationYearTranslationDto>
  startDate?: Date
  endDate?: Date
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormOperationYearTranslatedDto {
  name?: string
  startDate?: Date
  endDate?: Date
  performanceFormOperationYearId?: string
  translations?: Array<PerformanceFormOperationYearTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormOperationYearTranslationDto {
  name?: string
  performanceFormOperationYearId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormPeriodApprovalDto {
  performanceFormPeriodId?: string
  performanceFormId?: string
  performanceApprovalFlowId?: string
  performanceApprovalFlowDetailId?: string
  approvalEmployeeId?: string
  approvalTypeId?: string
  returnApprovalTypeId?: string
  isAuthToEvaluate?: boolean
  performanceFormApprovalStatus?: PerformanceFormPeriodApprovalDtoPerformanceFormApprovalStatusEnum
  approvalOrder?: number
  isActiveApproval?: boolean
  rejectionNote?: string
  rejectionNotes?: Array<PerformanceFormPeriodApprovalRejectionNoteDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the performanceFormApprovalStatus property.
 */
export type PerformanceFormPeriodApprovalDtoPerformanceFormApprovalStatusEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'

export interface PerformanceFormPeriodApprovalEmployeeInputDto {
  employeeId?: string
  employeeNo?: string
  name?: string
  surname?: string
  approvals?: Array<PerformanceFormPeriodApprovalTypeInputDto>
}

export interface PerformanceFormPeriodApprovalInputDto {
  performanceFormId?: string
  approvalEmployeeId?: string
  approvalTypeId?: string
  isIgnoredChange?: boolean
}

export interface PerformanceFormPeriodApprovalRejectionNoteDto {
  performanceFormPeriodApprovalId?: string
  performanceFormId?: string
  performanceFormDetailId?: string
  rejectionNote?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormPeriodApprovalRequestDto {
  employeeApprovals?: Array<PerformanceFormPeriodApprovalEmployeeInputDto>
}

export interface PerformanceFormPeriodApprovalSimpleInputDto {
  id?: string
  name?: string
}

export interface PerformanceFormPeriodApprovalTypeInputDto {
  id?: string
  approvalType?: PerformanceFormPeriodApprovalSimpleInputDto
  approvalEmployee?: PerformanceFormPeriodApprovalSimpleInputDto
}

export interface PerformanceFormPeriodDto {
  periodDateBegin?: Date
  periodDateEnd?: Date
  periodType?: PerformanceFormPeriodDtoPeriodTypeEnum
  performanceFormId?: string
  periodId?: string
  approvals?: Array<PerformanceFormPeriodApprovalDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the periodType property.
 */
export type PerformanceFormPeriodDtoPeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PerformanceFormRejectionDetailDto {
  performanceFormDetailId?: string
  rejectionNote?: string
}

export interface PerformanceFormRejectionDto {
  performanceFormId?: string
  rejectionNotes?: Array<PerformanceFormRejectionDetailDto>
}

export interface GroupedRejectionNoteDto {
  performanceFormDetailId: string;
  rejectionNote: string;
  targetTitle: string;
}

export interface RejectionNoteDto {
  groupName: string;
  groupedRejectionNotes: GroupedRejectionNoteDto[];
}

export interface TargetRejectionDto {
  performanceFormId: string;
  rejectionNotes: RejectionNoteDto[];
}
export interface PerformanceFormRejectionGroupDto {
  groupName: string;
  groupedRejectionNotes: PerformanceFormRejectionDetailDto[];
}

export interface PerformanceFormStatusDto {
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormSummaryViewModel {
  formOwnerFullName?: string
  approvalForViewer?: PerformanceFormPeriodApprovalDto
  approvalUpNext?: PerformanceFormPeriodApprovalDto
  performanceForm?: PerformanceFormDto
  isAdminView?: boolean
  isChiefOfficer?: boolean
}

export interface PerformanceFormTargetCorrelationDto {
  targetLibraryId?: string
  targetLibraryGroupId?: string
  performanceFormDetailId?: string
  recommendedPerformanceFormDetailId?: string
  name?: string
  employeeFullName?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetCorrelationRequest {
  targetLibraryId?: string
  recommendedPerformanceFormDetailId?: string
}


export interface TargetOfferResultDto {
  offers: PerformanceFormTargetOfferDto[]
}

export interface PerformanceFormTargetOfferDto {
  name?: string
  description?: string
  minValue?: string
  targetValue?: string
  maxValue?: string
  isTeamOrPersonal?: boolean
  measurementCriteriaType?: PerformanceFormDetailDtoTargetUnitEnum
}

export interface PerformanceFormTargetCorrelationViewModel {
  id?: string
  targetLibraryId?: string
  targetLibraryGroupId?: string
  name?: string
  recommendedPerformanceFormDetailId?: string
  employeeFullName?: string
}

export interface PerformanceFormTargetRecommendationDto {
  performanceFormDetailId?: string
  employeeId?: string
  targetName?: string
  employeeFullName?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateBulkUpdateRequestDto {
  performanceFormTemplateDetailId?: string
  fieldsToBeUpdated?: Array<PerformanceFormTemplateBulkUpdateRequestDtoFieldsToBeUpdatedEnum>
}

/**
 * Enum for the fieldsToBeUpdated property.
 */
export type PerformanceFormTemplateBulkUpdateRequestDtoFieldsToBeUpdatedEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'

export interface PerformanceFormTemplateDetailCompanyPriorityDto {
  performanceFormTemplateDetailId?: string
  performanceFormTemplateId?: string
  companyPrioritiesLibraryId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateDetailCompetenceGroupRange {
  performanceFormTemplateDetailId?: string
  performanceFormTemplateId?: string
  competenceGroupId?: string
  competenceGroupMaximumValue?: number
  competenceGroupMinimumValue?: number
  companyId?: string
  company?: Company
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateDetailCompetenceGroupRangeDto {
  performanceFormTemplateDetailId?: string
  performanceFormTemplateId?: string
  competenceGroupId?: string
  competenceGroupMaximumValue?: number
  competenceGroupMinimumValue?: number
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateDetailDto {
  performanceFormTemplateId?: string
  parentPerformanceFormTemplateDetailId?: string
  parentPerformanceFormTemplateDetail?: PerformanceFormTemplateDetailDto
  childPerformanceFormTemplateDetails?: Array<PerformanceFormTemplateDetailDto>
  formDetailType?: PerformanceFormTemplateDetailDtoFormDetailTypeEnum
  childFormDetailType?: PerformanceFormTemplateDetailDtoChildFormDetailTypeEnum
  displayOrder?: number
  targetStartDate?: Date
  targetEndDate?: Date
  isTargetVisibleToEveryone?: boolean
  weight?: number
  minimumWeight?: number
  maximumWeight?: number
  scoringType?: PerformanceFormTemplateDetailDtoScoringTypeEnum
  minimumTargetFromCatalog?: number
  maximumTargetFromCatalog?: number
  minimumDetail?: number
  maximumDetail?: number
  maximumTargetTotal?: number
  targetAssignmentType?: PerformanceFormTemplateDetailDtoTargetAssignmentTypeEnum
  targetUnit?: PerformanceFormTemplateDetailDtoTargetUnitEnum
  targetValueMinimumDate?: Date
  targetValueDate?: Date
  targetValueMaximumDate?: Date
  targetValueMinimumPercent?: number
  targetValuePercent?: number
  targetValueMaximumPercent?: number
  targetValueMinimumNumber?: number
  targetValueNumber?: number
  targetValueMaximumNumber?: number
  targetValueMinimumText?: string
  targetValueText?: string
  targetValueMaximumText?: string
  competenceAssignmentType?: PerformanceFormTemplateDetailDtoCompetenceAssignmentTypeEnum
  templateDetailUnitPermissionType?: PerformanceFormTemplateDetailDtoTemplateDetailUnitPermissionTypeEnum
  templateDetailWeightPermissionType?: PerformanceFormTemplateDetailDtoTemplateDetailWeightPermissionTypeEnum
  templateDetailMinimumMaximumScorePermissionType?: PerformanceFormTemplateDetailDtoTemplateDetailMinimumMaximumScorePermissionTypeEnum
  evaluationResultByAdmin?: boolean
  extraFieldId1?: string
  extraFieldId2?: string
  extraFieldId3?: string
  extraFieldId4?: string
  performanceFormTemplateDetailCompetenceGroupRange?: Array<PerformanceFormTemplateDetailCompetenceGroupRange>
  performanceFormTemplateDetailCompanyPriorities?: Array<PerformanceFormTemplateDetailCompanyPriorityDto>
  translations?: Array<PerformanceFormTemplateDetailTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the formDetailType property.
 */
export type PerformanceFormTemplateDetailDtoFormDetailTypeEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the childFormDetailType property.
 */
export type PerformanceFormTemplateDetailDtoChildFormDetailTypeEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the scoringType property.
 */
export type PerformanceFormTemplateDetailDtoScoringTypeEnum = '0' | '1' | '2'

/**
 * Enum for the targetAssignmentType property.
 */
export type PerformanceFormTemplateDetailDtoTargetAssignmentTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the targetUnit property.
 */
export type PerformanceFormTemplateDetailDtoTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the competenceAssignmentType property.
 */
export type PerformanceFormTemplateDetailDtoCompetenceAssignmentTypeEnum = '0' | '1' | '2' | '3'

/**
 * Enum for the templateDetailUnitPermissionType property.
 */
export type PerformanceFormTemplateDetailDtoTemplateDetailUnitPermissionTypeEnum = '0' | '1'

/**
 * Enum for the templateDetailWeightPermissionType property.
 */
export type PerformanceFormTemplateDetailDtoTemplateDetailWeightPermissionTypeEnum = '0' | '1'

/**
 * Enum for the templateDetailMinimumMaximumScorePermissionType property.
 */
export type PerformanceFormTemplateDetailDtoTemplateDetailMinimumMaximumScorePermissionTypeEnum = '0' | '1'

export interface PerformanceFormTemplateDetailTranslationDto {
  performanceFormTemplateDetailId?: string
  title?: string
  titleDescription?: string
  targetName?: string
  targetDescription?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateDto {
  isActive?: boolean
  performanceFormTemplateGroupId?: string
  employeeGroupId?: string
  performanceFormTemplateGroup?: PerformanceFormTemplateGroupDto
  performanceFormTemplateDetails?: Array<PerformanceFormTemplateDetailDto>
  firstLevelPerformanceFormTemplateDetails?: Array<PerformanceFormTemplateDetailDto>
  translations?: Array<PerformanceFormTemplateTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateGroupDto {
  isActive?: boolean
  isClosed?: boolean
  year?: number
  performanceFormOperationYearId?: string
  performanceFormTemplateGroupParameter?: PerformanceFormTemplateGroupParameterDto
  periods?: Array<PeriodDto>
  translations?: Array<PerformanceFormTemplateGroupTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateGroupParameterDto {
  performanceFormTemplateGroupId?: string
  willMeasurementCriteriaBeUsedForTargets?: boolean
  willTargetsBeAlwaysPrivate?: boolean
  willStrategicalPrioritiesBeUsed?: boolean
  canEmployeeSeeTheEvaluationScore?: boolean
  targetMinimumScore?: number
  targetMaximumScore?: number
  competenceMaximumScore?: number
  isShowCompletedEvaluationSurvery?: boolean
  isShowCompletedEvaluationSurveryForEmployee?: boolean
  willExtraParametersBeUsed?: boolean
  numberOfExtraParameters?: number
  willTagsBeUsed?: boolean
  isShowComparisonAnalysisScore?: boolean
  willBeIncludedInCompanyComparisonAnalysis?: boolean
  isShowEvaluationScale?: boolean
  isActiveEnterDifferentYear?: boolean
  isSecondManagerCanApprove?: boolean
  isManagerCalculateUnderMinimumScore?: boolean
  isEvaluateWithGrade?: boolean
  targetScore?: number
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  isShowCalibrationScore?: boolean
  isShowDisciplineInformation?: boolean
}

export interface PerformanceFormTemplateGroupTranslatedDto {
  isActive?: boolean
  isClosed?: boolean
  year?: number
  name?: string
  performanceFormTemplateGroupParameter?: PerformanceFormTemplateGroupParameterDto
  periods?: Array<PeriodDto>
  performanceFormOperationYearId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateGroupTranslationDto {
  performanceFormTemplateGroupId?: string
  name?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateQuery {
  id?: string
  performanceFormTemplateName?: string
  performanceFormTemplateGroupId?: string
  performanceFormTemplateGroupName?: string
  year?: number
  performanceFormOperationYearId?: string
  isActive?: boolean
}

export interface PerformanceFormTemplateTranslatedDto {
  name?: string
  isActive?: boolean
  performanceFormTemplateGroupId?: string
  employeeGroupId?: string
  performanceFormTemplateGroup?: PerformanceFormTemplateGroupTranslatedDto
  firstLevelPerformanceFormTemplateDetails?: Array<PerformanceFormTemplateDetailDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PerformanceFormTemplateTranslationDto {
  performanceFormTemplateId?: string
  name?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PeriodDto {
  performanceFormTemplateGroupId?: string
  periodDateBegin?: Date
  periodDateEnd?: Date
  periodType?: PeriodDtoPeriodTypeEnum
  performanceApprovalFlowId?: string
  performanceApprovalFlow?: PerformanceApprovalFlowDto
  translations?: Array<PeriodTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  isVisible?: boolean
}

/**
 * Enum for the periodType property.
 */
export type PeriodDtoPeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PeriodTranslatedDto {
  name?: string
  periodDateBegin?: Date
  periodDateEnd?: Date
  periodType?: PeriodTranslatedDtoPeriodTypeEnum
  performanceFormTemplateGroupId?: string
  performanceApprovalFlowId?: string
  performanceApprovalFlow?: PerformanceApprovalFlowDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the periodType property.
 */
export type PeriodTranslatedDtoPeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PeriodTranslationDto {
  name?: string
  periodId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export type PeriodTypeEnum = '0' | '1' | '2' | '3'

export interface PeriodTypeDto {
  translations?: Array<PeriodTypeTranslationDto>
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PeriodTypeTranslationDto {
  keyResultStatuses?: PeriodTypeEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PermissionItemViewModel {
  value?: string
  source?: string
  type?: PermissionItemViewModelTypeEnum
}

/**
 * Enum for the type property.
 */
export type PermissionItemViewModelTypeEnum = '1' | '2'

export interface PhoneDto {
  phoneTypeId?: string
  countryCode?: string
  areaCode?: string
  detail?: string
  employeeId?: string
  phoneType?: PhoneTypeDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PhoneTypeDto {
  name?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PositionDto {
  code?: string
  name?: string
  positionGroup?: PositionGroupDto
  isActive?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PositionGroupDto {
  code?: string
  name?: string
  isActive?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PreviousPerformanceFormDetailDto {
  previousPerformanceFormId?: string
  title?: string
  name?: string
  description?: string
  formType?: PreviousPerformanceFormDetailDtoFormTypeEnum
  weight?: number
  employeeSuccessRate?: number
  managerSuccessRate?: number
  parentPreviousPerformanceFormDetailId?: string
  childPreviousPerformanceFormDetails?: Array<PreviousPerformanceFormDetailDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the formType property.
 */
export type PreviousPerformanceFormDetailDtoFormTypeEnum = '0' | '1' | '2'

export interface PreviousPerformanceFormDetailViewModel {
  employeeNo?: string
  location?: string
  performanceYearScore?: string
  title?: string
  titleWeight?: number
  titleScore?: number
  targetOrCompetenceName?: string
  description?: string
  weight?: number
  score?: number
}

export interface PreviousPerformanceFormDto {
  employeeId?: string
  year?: number
  employeeSuccessRate?: string
  managerSuccessRate?: string
  previousPerformanceFormDetails?: Array<PreviousPerformanceFormDetailDto>
  performanceFormOperationYearId?: string
  firstLevelPerformanceFormDetails?: Array<PreviousPerformanceFormDetailDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface PreviousPerformanceFormViewModel {
  employeeeId?: string
  employeeeFullName?: string
  previousPerformanceFormList?: Array<CombinedPerformanceFormViewModel>
}

export interface PublishedAnnouncementDto {
  announcementType?: PublishedAnnouncementDtoAnnouncementTypeEnum
  content?: string
  announcementId?: string
}

/**
 * Enum for the announcementType property.
 */
export type PublishedAnnouncementDtoAnnouncementTypeEnum = '0' | '1' | '2'

export interface QuittedActiveApproversQuery {
  employeeId?: string
  employeeName?: string
  employeeSurname?: string
  employeeNo?: string
  quitDate?: Date
  approvalCount?: number
}

export interface RejectThreeSixtyEvaluatorsRequest {
  threeSixtyPeriodId?: string
  threeSixtyEvaluateeEmployeeId?: string
  evaluatorRejectionNotes?: Array<RejectThreeSixtyEvaluatorsRequestEvaluator>
}

export interface RejectThreeSixtyEvaluatorsRequestEvaluator {
  threeSixtyEvaluatorEmployeeId?: string
  rejectionNote?: string
}

export interface RemoveThreeSixtyEmployeeLevel {
  employeeId?: string
  threeSixtyPeriodId?: string
}

export interface RemoveThreeSixtyEmployeeLevelBulkRequest {
  removeThreeSixtyEmployeeLevels?: Array<RemoveThreeSixtyEmployeeLevel>
}

export interface RoleViewModel {
  roleName?: string
}

export interface RollBackThreeSixtyFormRequest {
  periodId?: string
  employeeId?: string
}

export interface SaveCompetenceEvaluationViewModel {
  performanceFormId?: string
  selectedScales?: Array<CompetenceEvaluationSelectedScaleIdViewModel>
  year?: number,
  performanceFormOperationYearId?: string,
  isFlexibleYear: boolean
}

export interface SavePreviousPerformanceFormViewModel {
  employeeNo?: string
  locationCode?: string
  performanceYearScore?: string
  yearParams?: YearParameter
  title?: string
  titleWeight?: number
  titleScore?: number
  name?: string
  description?: string
  weight?: number
  score?: number
}


export interface SaveCalibrationScoreViewModel {
  performanceFormId?: string
  employeeNo?: string
  name?: string
  surname?: string
  calibrationSuccessRate?: string
  yearParams?: YearParameter
  performanceFormYearId: string
  year: number
}


export interface SaveThreeSixtyEvaluationRequest {
  threeSixtyEvaluatorId?: string
  threeSixtyPeriodId?: string
  selectedScales?: Array<SaveThreeSixtyEvaluationScaleRequest>
  allChoicesSelected?: boolean
}

export interface SaveThreeSixtyEvaluationScaleRequest {
  threeSixtyEvaluationId?: string
  selectedCompetenceEvaluationScaleId?: string
  competenceId?: string
  competenceName?: string
  competenceComment?: string
  evaluateeEmployeeId?: string
}

export interface SaveThreeSixtyEvaluatorsAsApprovedRequest {
  periodId?: string
  employeeId?: string
  evaluators?: Array<SaveThreeSixtyEvaluatorsEmployeeRequest>
}

export interface SaveThreeSixtyEvaluatorsEmployeeRequest {
  threeSixtyRoleId?: string
  evaluatorEmployeeId?: string
}

export interface SaveThreeSixtyEvaluatorsRequest {
  periodId?: string
  employeeId?: string
  evaluators?: Array<SaveThreeSixtyEvaluatorsEmployeeRequest>
}

export interface ScheduledJobExecutionRequest {
  cronJobName?: string
}

export interface ScheduledJobManagerViewModel {
  cronJobs?: Array<JobItem>
}

export interface SendFormApprovalReminderRequest {
  performanceFormIds?: Array<string>
}

export interface SendThreeSixtyEvaluationReminderRequest {
  approvalEmployees?: Array<EmployeeSearchApprovalResultDto>
  threeSixtyPeriodId?: string
  employeeIds?: Array<string>
  threeSixtyPeriodName?: string
}

export interface SendToApprovalThreeSixtyEvaluatorsRequest {
  periodId?: string
  employeeeId?: string
}

export interface SpreadsheetDto {
  employeeId?: string
  platform?: SpreadsheetDtoPlatformEnum
  deviceType?: SpreadsheetDtoDeviceTypeEnum
  systemVersion?: string
  buildNumber?: string
  brandName?: string
  modelName?: string
  appVersion?: string
  deviceUniqueId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the platform property.
 */
export type SpreadsheetDtoPlatformEnum = '0' | '1' | '2'

/**
 * Enum for the deviceType property.
 */
export type SpreadsheetDtoDeviceTypeEnum = '0' | '1' | '2' | '3'

export interface StrategyBasedObjectives {
  strategyId?: string
  name?: string
  description?: string
  objectives?: Array<OkrObjectiveDto>
}

export interface StrategyObjectivesRequest {
  name?: string
  relatedIds?: Array<string>
  onlyMine?: boolean
}

export interface SubstituteApproversInputDto {
  yearParams?: YearParameter
  sourceEmployeeId?: string
  targetEmployeeId?: string
}

export interface TargetAssignmentTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetCorrelationViewModel {
  employeeNo?: string
  employeeFullName?: string
  positionName?: string
  targetName?: string
  isLibraryTarget?: boolean
  employeeId?: string
}

export interface TargetEmployeeEvaluationViewModel {
  targetName?: string
  targetUnit?: TargetEmployeeEvaluationViewModelTargetUnitEnum
  actualDate?: Date
  actualPercent?: number
  actualNumber?: number
  actualText?: string
  displayOrder?: number
  targetEvaluationStatus?: TargetEmployeeEvaluationViewModelTargetEvaluationStatusEnum
  targetStartDate?: Date
  targetEndDate?: Date
  targetDescription?: string
  targetValueMinimumDate?: Date
  targetValueDate?: Date
  targetValueMaximumDate?: Date
  targetValueMinimumPercent?: number
  targetValuePercent?: number
  targetValueMaximumPercent?: number
  targetValueMinimumNumber?: number
  targetValueNumber?: number
  targetValueMaximumNumber?: number
  targetValueMinimumText?: string
  targetValueText?: string
  targetValueMaximumText?: string
  completionDate?: Date
  performanceFormDetailKeyResults?: Array<PerformanceFormDetailKeyResultDto>
  isTargetVisibleToEveryone?: boolean
  id?: string
  formId?: string
  weight?: number
  managerSuccessRate?: number
  managerSuccessRateScale?: string
  managerEvaluationNote?: string
  employeeSuccessRate?: number
  employeeSuccessRateScale?: string
  completionNote?: string
  isRequired?: boolean
  aiTargetOfferId?: string
}

/**
 * Enum for the targetUnit property.
 */
export type TargetEmployeeEvaluationViewModelTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the targetEvaluationStatus property.
 */
export type TargetEmployeeEvaluationViewModelTargetEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4'

export interface TargetHeaderEvaluationViewModel {
  targets?: Array<TargetEmployeeEvaluationViewModel>
  formId?: string
  formDetailId?: string
  title?: string
  weight?: number
  managerSuccessRate?: number
  managerSuccessRateScale?: string
  managerEvaluationNote?: string
  employeeSuccessRate?: number
  employeeSuccessRateScale?: string
  completionNote?: string
  employeeTag?: PerformanceFormDetailTagTranslatedDto
  managerTag?: PerformanceFormDetailTagTranslatedDto
}

export interface TargetLibraryDto {
  isActive?: boolean
  targetLibraryGroupId?: string
  translations?: Array<TargetLibraryTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetLibraryGroupDto {
  isActive?: boolean
  translations?: Array<TargetLibraryGroupTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetLibraryGroupTranslatedDto {
  isActive?: boolean
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetLibraryGroupTranslationDto {
  name?: string
  targetLibraryGroupId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetLibraryGroupViewModel {
  targetLibraryGroupId?: string
  groupName?: string
  performanceFormTargetCorrelations?: Array<PerformanceFormTargetCorrelationDto>
}

export interface TargetLibraryTranslatedDto {
  name?: string
  description?: string
  isActive?: boolean
  targetLibraryGroupId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetLibraryTranslationDto {
  name?: string
  description?: string
  targetLibraryId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetOrderViewModel {
  id?: string
  targetName?: string
  pointer?: number
}

export interface CalibrationScoreResultDto {
  reason?: string
}



export interface TargetRecommendationEmployeeViewModel {
  id?: string
  employeeId?: string
  employeeNo?: string
  fullName?: string
  position?: string
  isCorrelated?: boolean
}

export interface TargetRecommendationViewModel {
  performanceFormDetailId?: string
  targetName?: string
  recommendedEmployees?: Array<TargetRecommendationEmployeeViewModel>
}

export interface TargetScoringTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TargetUnitDto {
  translations?: Array<TargetUnitTranslationDto>
  id?: TargetUnitDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the id property.
 */
export type TargetUnitDtoIdEnum = '0' | '1' | '2' | '3' | '4'

export interface TargetUnitTranslatedDto {
  name?: string
  targetUnit?: TargetUnitTranslatedDtoTargetUnitEnum
  id?: TargetUnitTranslatedDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the targetUnit property.
 */
export type TargetUnitTranslatedDtoTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the id property.
 */
export type TargetUnitTranslatedDtoIdEnum = '0' | '1' | '2' | '3' | '4'

export interface TargetUnitTranslationDto {
  targetUnit?: TargetUnitTranslationDtoTargetUnitEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the targetUnit property.
 */
export type TargetUnitTranslationDtoTargetUnitEnum = '0' | '1' | '2' | '3' | '4'

export interface TargetWeightinessViewModel {
  maximumTargetCount?: number
  enteredTargetCount?: number
  minimumTargetCount?: number
  enteredTargetTotalWeight?: number
}

export interface TeamEvaluationStatusViewModel {
  id?: string
  fullName?: string
  imageFileName?: string
  positionName?: string
  teamMemberCount?: number
  performanceFormId?: string
  status?: number
  statusText?: string
  statusRelatedEmployeeFullName?: string
  isTeamMember?: boolean
  teamMembers?: Array<TeamEvaluationStatusViewModel>
  activePeriodType?: number
  isShowCompletedEvaluationSurvery?: boolean
  isEvaluationSurveyCompleted?: boolean
  isShowCompletedEvaluationSurveyForEmployee?: boolean
  isEvaluationSurveyCompletedForEmployee?: boolean
  year?: number
  performanceFormOperationYearId?: string
  completedTargetCount?: number
  totalTargetCount?: number
}

export interface TeamMemberPerformanceFormAndScoreQueryDto {
  performanceFormList?: Array<TeamMemberPerformanceFormQueryDto>
  median?: number
  average?: number
  firstLevelMedian?: number
  firstLevelAverage?: number
  maxTargetMaximumScore?: number
  chartData?: Array<ComparisonAnalysisChartDataResultDto>
}

export interface TeamMemberPerformanceFormQueryDto {
  id?: string
  name?: string
  middleName?: string
  surname?: string
  imageFileName?: string
  positionName?: string
  teamMemberCount?: number
  performanceFormId?: string
  employeeId?: string
  status?: number
  statusText?: string
  statusRelatedEmployeeName?: string
  statusRelatedEmployeeMiddleName?: string
  statusRelatedEmployeeSurname?: string
  isTeamMember?: boolean
  teamMembers?: Array<TeamMemberPerformanceFormQueryDto>
  activePeriodType?: number
  isCrossCompanyMember?: boolean
  isFirstLevelEmployee?: boolean
  managerSuccessRate?: number
  targetMaximumScore?: number
  completedTargetCount?: number
  totalTargetCount?: number
  isFormInRevision?: boolean
}

export interface TeamMemberQueryDto {
  id?: string
  name?: string
  surname?: string
  imageFileName?: string
  teamMemberCount?: number
  isTeamMember?: boolean
  teamMembers?: Array<TeamMemberQueryDto>
}

export interface TeamOfManagerDto {
  employeeId?: string
  managerId?: string
  employeeNo?: string
  name?: string
  surname?: string
  positionName?: string
  imageFileNAme?: string
  members?: Array<TeamOfManagerDto>
}

export interface TeamPerformanceFormStatusMetricQueryDto {
  status?: number
  count?: number
}

export interface TemplateDetailAndFormDetailDifference {
  fieldType?: TemplateDetailAndFormDetailDifferenceFieldTypeEnum
  fieldDescription?: string
  canBeUpdated?: boolean
  noUpdatereason?: string
  affectedRowCount?: number
}

/**
 * Enum for the fieldType property.
 */
export type TemplateDetailAndFormDetailDifferenceFieldTypeEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'

export interface TemplateDetailAndFormDetailDifferenceList {
  fieldDifferences?: Array<TemplateDetailAndFormDetailDifference>
}

export interface TemplateDetailPermissionTypeDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TermsOfUseAcceptanceHistoryDto {
  termsOfUseId?: string
  acceptDateUTC?: Date
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TermsOfUseDto {
  isActive?: boolean
  isApproveNeeded?: boolean
  translations?: Array<TermsOfUseTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TermsOfUseTranslatedDto {
  isActive?: boolean
  isApproveNeeded?: boolean
  name?: string
  description?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface TermsOfUseTranslationDto {
  termsOfUseId?: string
  name?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyCompetenceCommentDto {
  toEmployeeId?: string
  fromEmployeeId?: string
  competenceId?: string
  periodId?: string
  competenceComment?: string
  competenceName?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyCompetenceEvaluationScaleDto {
  year?: number
  score?: number
  isActive?: boolean
  includedInCalculation?: boolean
  isCommentMandatory?: boolean
  translations?: Array<ThreeSixtyCompetenceEvaluationScaleTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyCompetenceEvaluationScaleTranslatedDto {
  year?: number
  score?: number
  isActive?: boolean
  includedInCalculation?: boolean
  isCommentMandatory?: boolean
  description?: string
  translations?: Array<ThreeSixtyCompetenceEvaluationScaleTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyCompetenceEvaluationScaleTranslationDto {
  description?: string
  threeSixtyCompetenceEvaluationScaleId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyEmployeeApproval {
  employeeId?: string
  approvalEmployeeId?: string
  threeSixtyPeriodId?: string
  companyId?: string
  company?: Company
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyEmployeeApprovalDto {
  employeeId?: string
  approvalEmployeeId?: string
  threeSixtyPeriodId?: string
  companyId?: string
  company?: Company
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyEmployeeGroupBulkRequestDto {
  employeeIds?: Array<string>
  threeSixtyGroupIds?: Array<string>
}

export interface ThreeSixtyEmployeeGroupDto {
  threeSixtyGroupId?: string
  employeeId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyEmployeeGroupResultDto {
  succesfullyAddedEmployeeIds?: Array<string>
  periodStartedEmployeeIds?: Array<string>
}

export interface ThreeSixtyEmployeeLevelDto {
  employeeId?: string
  employeeLevelId?: string
  employeeSubLevelId?: string
  threeSixtyPeriodId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyEmployeeLevelRequest {
  threeSixtyEmployeeLevelDtos?: Array<ThreeSixtyEmployeeLevelDto>
}

export interface ThreeSixtyEvalautorAssignDto {
  threeSixtyPeriodId?: string
  evaluators?: Array<ThreeSixtyEvaluatorAssignViewModel>
}

export interface ThreeSixtyEvaluatingEmployees {
  threeSixtyEvaluatorId?: string
  evaluationStatus?: ThreeSixtyEvaluatingEmployeesEvaluationStatusEnum
  evaluationStatusDescription?: string
  threeSixtyRoleId?: string
  threeSixtyRoleName?: string
  evaluateeEmployeeId?: string
  evaluateeEmployeeNo?: string
  evaluateeEmployeeName?: string
  periodStatus?: ThreeSixtyEvaluatingEmployeesPeriodStatusEnum
  periodStatusDescription?: string,
  showEvaluationResultByMinimumOneEvaluationInOneRole?: boolean
}

/**
 * Enum for the evaluationStatus property.
 */
export type ThreeSixtyEvaluatingEmployeesEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

/**
 * Enum for the periodStatus property.
 */
export type ThreeSixtyEvaluatingEmployeesPeriodStatusEnum = '0' | '1' | '2' | '3' | '4'

export interface ThreeSixtyEvaluatingEmployeesViewModel {
  threeSixtyPeriodId?: string
  threeSixtyPeriodName?: string
  evaluations?: Array<ThreeSixtyEvaluatingEmployees>
  isAdminView?: boolean
}

export interface ThreeSixtyEvaluationBehaviorIndicatorViewModel {
  threeSixtyEvaluationId?: string
  competenceEvaluationScaleId?: string
  competenceBehaviorIndicatorId?: string
  competenceBehaviorIndicatorName?: string
  competenceId?: string
  competenceName?: string
}

export interface ThreeSixtyEvaluationCompetenceGroupViewModel {
  competenceGroupId?: string
  competenceGroupName?: string
  competences?: Array<ThreeSixtyEvaluationCompetenceViewModel>
}

export interface ThreeSixtyEvaluationCompetenceViewModel {
  competenceId?: string
  competenceName?: string
  competenceDescription?: string
  competenceComment?: string
  competenceCommentList?: Array<ThreeSixtyCompetenceCommentDto>
  behaviorIndicators?: Array<ThreeSixtyEvaluationBehaviorIndicatorViewModel>
}

export interface ThreeSixtyEvaluationDto {
  threeSixtyEvaluatorId?: string
  competenceId?: string
  competenceBehaviorIndicatorId?: string
  competenceEvaluationScaleId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyEvaluationReportQuery {
  evaluator?: string
  roleCount?: number
}

export interface ThreeSixtyEvaluationReportViewModel {
  evaluatorList?: Array<ThreeSixtyEvaluationReportQuery>
  competencesAndBehaviourScoreList?: Array<ThreeSixtyEvaluationScoreCompetenceViewModel>
  showScoreByOneEvaluationInOneRole?: boolean
}

export interface ThreeSixtyEvaluationResultDto {
  scoreType?: ThreeSixtyEvaluationResultDtoScoreTypeEnum
  threeSixtyPeriodId?: string
  evaluateeEmployeeId?: string
  competenceId?: string
  evaluatorEmployeeId?: string
  threeSixtyRoleId?: string
  score?: number
  behaviorIndicatorId?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the scoreType property.
 */
export type ThreeSixtyEvaluationResultDtoScoreTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'

export interface ThreeSixtyEvaluationScoreBehaviorIndicatorViewModel {
  competenceBehaviorIndicatorId?: string
  competenceBehaviorIndicatorName?: string
  ownScore?: number
  othersScore?: number
  avgScore?: number
}

export interface ThreeSixtyEvaluationScoreCompetenceViewModel {
  competenceId?: string
  competenceName?: string
  scoreType?: number
  ownScore?: number
  avgScore?: number
  competenceComment?: string
  competenceCommentList?: Array<ThreeSixtyCompetenceCommentDto>
  behaviorIndicators?: Array<ThreeSixtyEvaluationScoreBehaviorIndicatorViewModel>
}

export interface ThreeSixtyEvaluationTransferDto {
  totalNumberOfRecords?: number
  totalNumberOfSuccesfullRecords?: number
  totalNumberOfFailureRecords?: number
}

export interface ThreeSixtyEvaluationViewModel {
  threeSixtyPeriodId?: string
  evaluateeEmployeeId?: string
  evaluateeEmployeeName?: string
  evaluationScaleYear?: number
  competenceGroups?: Array<ThreeSixtyEvaluationCompetenceGroupViewModel>
  evaluationStatus?: ThreeSixtyEvaluationViewModelEvaluationStatusEnum
}

/**
 * Enum for the evaluationStatus property.
 */
export type ThreeSixtyEvaluationViewModelEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface ThreeSixtyEvaluatorAssignViewModel {
  threeSixtyRoleId?: string
  evaluateeEmployeeId?: string
  evaluatorEmployeeId?: string
}

export interface ThreeSixtyEvaluatorDto {
  threeSixtyPeriodId?: string
  evaluationStatus?: ThreeSixtyEvaluatorDtoEvaluationStatusEnum
  threeSixtyRoleId?: string
  evaluateeEmployeeId?: string
  evaluatorEmployeeId?: string
  rejectionNote?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the evaluationStatus property.
 */
export type ThreeSixtyEvaluatorDtoEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface ThreeSixtyEvaluatorRequest {
  employeeApprovals?: Array<ThreeSixtyEvalautorAssignDto>
}

export interface ThreeSixtyEvaluatorsEmployee {
  evaluationStatus?: ThreeSixtyEvaluatorsEmployeeEvaluationStatusEnum
  evaluationStatusDescription?: string
  evaluatorEmployeeId?: string
  evaluatorEmployeeNo?: string
  evaluatorEmployeeName?: string
  rejectionNote?: string
}

/**
 * Enum for the evaluationStatus property.
 */
export type ThreeSixtyEvaluatorsEmployeeEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface ThreeSixtyEvaluatorsRole {
  threeSixtyEvaluatorRoleId?: string
  threeSixtyEvaluatorRoleName?: string
  weight?: number
  minimumNumberOfPeople?: number
  maximumNumberOfPeople?: number
  isEvaluatorMandatory?: boolean
  employees?: Array<ThreeSixtyEvaluatorsEmployee>
  fixedThreeSixtyRole?: ThreeSixtyRoleDtoFixedThreeSixtyRoleEnum
}

export interface ThreeSixtyEvaluatorsViewModel {
  threeSixtyPeriodId?: string
  threeSixtyPeriodName?: string
  overallEvaluationStatus?: ThreeSixtyEvaluatorsViewModelOverallEvaluationStatusEnum
  overallEvaluationStatusDescription?: string
  threeSixtyEvaluatorsRoles?: Array<ThreeSixtyEvaluatorsRole>
  threeSixtyApprovalName?: string
  isAdminView?: boolean
}

/**
 * Enum for the overallEvaluationStatus property.
 */
export type ThreeSixtyEvaluatorsViewModelOverallEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface ThreeSixtyGroupDto {
  isActive?: boolean
  translations?: Array<ThreeSixtyGroupTranslationDto>
  threeSixtyGroupRoles?: Array<ThreeSixtyGroupRoleDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyGroupRoleDto {
  threeSixtyGroupId?: string
  threeSixtyRoleId?: string
  weight?: number
  minimumNumberOfPeople?: number
  maximumNumberOfPeople?: number
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  isContainsFilteredRole?: boolean
}

export interface ThreeSixtyGroupStatusQuery {
  isUsedGroup?: boolean
}

export interface ThreeSixtyGroupTranslatedDto {
  isActive?: boolean
  name?: string
  description?: string
  threeSixtyGroupRoles?: Array<ThreeSixtyGroupRoleDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyGroupTranslationDto {
  threeSixtyGroupId?: string
  name?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyManagerDashboardQuery {
  periodId?: string
  isPeriodActive?: boolean
  displayEvaluationScoreInPortal?: boolean
  periodStatus?: ThreeSixtyManagerDashboardQueryPeriodStatusEnum
  periodName?: string
  totalMinimumNumberOfPeople?: number
  myEvaluatorsCount?: number
  myEvaluatorsFirstStatus?: ThreeSixtyManagerDashboardQueryMyEvaluatorsFirstStatusEnum
  totalShouldBeEvaluatedCount?: number
  waitingEvaluationCount?: number
  waitingApprovalCount?: number
  totalEvaluationScore?: number
  employeeId?: string
  employeeFullName?: string,
  showEvaluationResultByMinimumOneEvaluationInOneRole?: boolean
}

/**
 * Enum for the periodStatus property.
 */
export type ThreeSixtyManagerDashboardQueryPeriodStatusEnum = '0' | '1' | '2' | '3' | '4'

/**
 * Enum for the myEvaluatorsFirstStatus property.
 */
export type ThreeSixtyManagerDashboardQueryMyEvaluatorsFirstStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface ThreeSixtyPeriodDto {
  isActive?: boolean
  displayEvaluationScoreInPortal?: boolean
  displayCompetenceCommentInThreeSixtyReport?: boolean
  evaluationScaleYear?: number
  isEvaluatorSelectedByHR?: boolean
  isWeightVisibleForPeriod?: boolean
  isShowScoreForNonCompleteEvaluation?: boolean
  translations?: Array<ThreeSixtyPeriodTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyPeriodEditViewModel {
  threeSixtyPeriod?: ThreeSixtyPeriodDto
  hasEvaluation?: boolean
}

export interface ThreeSixtyPeriodEmployeeBulkRequestDto {
  employeeIds?: Array<string>
  threeSixtyPeriodId?: string
  periodStatus?: ThreeSixtyPeriodEmployeeBulkRequestDtoPeriodStatusEnum
  isStartPeriod?: boolean
}

/**
 * Enum for the periodStatus property.
 */
export type ThreeSixtyPeriodEmployeeBulkRequestDtoPeriodStatusEnum = '0' | '1' | '2' | '3' | '4'

export interface ThreeSixtyPeriodEmployeeDto {
  threeSixtyPeriodId?: string
  employeeId?: string
  periodStatus?: ThreeSixtyPeriodEmployeeDtoPeriodStatusEnum
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the periodStatus property.
 */
export type ThreeSixtyPeriodEmployeeDtoPeriodStatusEnum = '0' | '1' | '2' | '3' | '4'

export interface ThreeSixtyPeriodResultDto {
  successfulCount?: number
  isEmployeeExistWithoutGroup?: boolean
  withoutGroupdEmployeeIdList?: Array<EmployeeLightQueryDto>
  isEmployeeExistWithoutEmployeeLevel?: boolean
  employeesWithoutEmployeeLevel?: Array<EmployeeLightQueryDto>
  emlpoyeeIdListStartedPeriod?: Array<string>
}

export interface ThreeSixtyPeriodStatusDto {
  name?: string
  id?: number
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyPeriodTranslatedDto {
  isActive?: boolean
  displayEvaluationScoreInPortal?: boolean
  evaluationScaleYear?: number
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyPeriodTranslationDto {
  name?: string
  threeSixtyPeriodId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyRoleDto {
  isActive?: boolean
  translations?: Array<ThreeSixtyRoleTranslationDto>
  fixedThreeSixtyRole?: ThreeSixtyRoleDtoFixedThreeSixtyRoleEnum
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the fixedThreeSixtyRole property.
 */
export type ThreeSixtyRoleDtoFixedThreeSixtyRoleEnum = '0' | '1' | '2' | '3'

export interface ThreeSixtyRoleStatusQuery {
  isUsedRole?: boolean
}

export interface ThreeSixtyRoleTranslatedDto {
  isActive?: boolean
  name?: string
  description?: string
  fixedThreeSixtyRole?: ThreeSixtyRoleTranslatedDtoFixedThreeSixtyRoleEnum
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the fixedThreeSixtyRole property.
 */
export type ThreeSixtyRoleTranslatedDtoFixedThreeSixtyRoleEnum = '0' | '1' | '2' | '3'

export interface ThreeSixtyRoleTranslationDto {
  threeSixtyRoleId?: string
  name?: string
  description?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface ThreeSixtyWaitingApprovalsEvaluatee {
  evaluateeEmployeeId?: string
  evaluateeEmployeeName?: string
  isEvaluateeQuitted?: boolean
  overallEvaluationStatus?: ThreeSixtyWaitingApprovalsEvaluateeOverallEvaluationStatusEnum
  overallEvaluationStatusDescription?: string
  evaluators?: Array<ThreeSixtyWaitingApprovalsEvaluator>
}

/**
 * Enum for the overallEvaluationStatus property.
 */
export type ThreeSixtyWaitingApprovalsEvaluateeOverallEvaluationStatusEnum =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'

export interface ThreeSixtyWaitingApprovalsEvaluator {
  threeSixtyEvaluatorId?: string
  evaluationStatus?: ThreeSixtyWaitingApprovalsEvaluatorEvaluationStatusEnum
  evaluationStatusDescription?: string
  threeSixtyRoleId?: string
  threeSixtyRoleName?: string
  evaluatorEmployeeId?: string
  evaluatorEmployeeName?: string
  isEvaluatorQuitted?: boolean
  rejectionNote?: string
}

/**
 * Enum for the evaluationStatus property.
 */
export type ThreeSixtyWaitingApprovalsEvaluatorEvaluationStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface ThreeSixtyWaitingApprovalsViewModel {
  threeSixtyPeriodId?: string
  threeSixtyPeriodName?: string
  evaluatees?: Array<ThreeSixtyWaitingApprovalsEvaluatee>
}

export interface TicketCategoryTranslatedDto {
  isActive?: boolean
  parentTicketCategoryId?: string
  key?: string
  description?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface UserCompanyDto {
  userId?: string
  companyId?: string
  companyEmployeeId?: string
  user?: UserDto
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface UserDto {
  userName?: string
  password?: string
  firstName?: string
  lastName?: string
  email?: string
  defaultLanguageCountryCode?: string
  companyId?: string
  companyEmployeeId?: string
  userSourceType?: UserDtoUserSourceTypeEnum
  userCompanies?: Array<UserCompanyDto>
  isActive?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the userSourceType property.
 */
export type UserDtoUserSourceTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'

export interface UserGroupDto {
  userId?: string
  groupId?: string
  companyId?: string
  user?: UserDto
  group?: GroupDto
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface UserGroupViewModel {
  groups?: Array<GroupDto>
  userGroups?: Array<UserGroupDto>
}

export interface UserModuleDto {
  teamEmployees?: Array<UserModuleEmployeeDto>
  moduleType?: UserModuleDtoModuleTypeEnum
  name?: string
  useForAllCompany?: boolean
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

/**
 * Enum for the moduleType property.
 */
export type UserModuleDtoModuleTypeEnum = '0' | '1' | '2'

export interface UserModuleEmployeeBulkRequestDto {
  employeeIds?: Array<string>
  userModuleIds?: Array<string>
}

export interface UserModuleEmployeeDto {
  userModuleId?: string
  employeeId?: string
  userModule?: UserModuleDto
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface UserModuleEmployeeListBulkRequestDto {
  employeeIds?: Array<string>
}

export interface UserModuleEmployeeQueryDto {
  employeeId?: string
  userModuleId?: string
  userModuleName?: string
}

export interface UserPermissionInfoViewModel {
  roles?: Array<RoleViewModel>
  permissionItems?: Array<PermissionItemViewModel>
}

export interface UserSearchResultDto {
  id?: string
  userName?: string
  firstName?: string
  lastName?: string
  email?: string
}

export interface WageLevelDto {
  code?: string
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface WorkingStatusDto {
  code?: string
  name?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface YearSelectOption {
  value?: any;
  label?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface YearParameter {
  year: number
  yearId: string
  startDate?: Date
  endDate?: Date
}

export interface CommonRequestDto {
  employeeId?: string;
  yearParams?: YearParameter;
}

export interface DashboardRequestDto extends CommonRequestDto { }

export interface StatusMetricRequestDto {
  isViewManager?: boolean
  yearParams?: YearParameter
}

export interface CommonFormRequestDto {
  formId?: string;
  yearParams?: YearParameter;
  isAdmin?: boolean;
}

export interface ScoreToPerformanceRequest {
  periodId?: string;
  yearParams?: YearParameter;
}

export interface IntegrationDataFilterDto {
  id?: string;
  companyId?: string;
  integrationType?: number;
  startDate?: Date;
  endDate?: Date;
  pageSize?: number;
}

export interface IntegrationSelectDto {
  value?: number;
  label?: string;
}

export interface IntegrationInfoDto {
  integrationTypes?: Array<IntegrationSelectDto>;
  lastProcess?: Date;
  lastStatus?: number;
  dateFormats?: Array<string>;
}
export interface StagingDataDto {
  id?: string;
  companyId?: string;
  integrationType?: number;
  status?: number;
  lastError?: string;
  createdDate?: Date;
  serializedData?: string;
}

export type OkrKeyResultStatusDtoIdEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6'

export type OkrKeyResultDtoKeyResultStatusEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6'

export interface OkrKeyResultStatusDto {
  translations?: Array<OkrKeyResultStatusTranslationDto>
  id?: OkrKeyResultStatusDtoIdEnum
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrKeyResultStatusTranslationDto {
  keyResultStatuses?: OkrKeyResultDtoKeyResultStatusEnum
  name?: string
  languageCountryCode?: string
  isDeleted?: boolean
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface IEmojiEvaluation {
  id?: number
  label?: string
  evaluationValue?: number
  emojiUrl?: string
}

export interface OkrPeriodDto {
  translations?: Array<OkrPeriodTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  parentOkrPeriodId?: string
  startDate?: Date
  endDate?: Date
  isParentPeriod?: boolean
  parentOkrPeriod?: OkrPeriodDto
  order?: number
  childPeriods?: Array<OkrPeriodDto>
}

export interface OkrPeriodTranslationDto {
  name?: string
  periodId?: string
  languageCountryCode?: string
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
}

export interface OkrPeriodTranslatedDto {
  name?: string
  translations?: Array<OkrPeriodTranslationDto>
  companyId?: string
  id?: string
  createdDateUTC?: Date
  createdUserId?: string
  modifiedDateUTC?: Date
  modifiedUserId?: string
  parentOkrPeriodId?: string
  startDate?: Date
  endDate?: Date
  isParentPeriod?: boolean
}

export interface UserStatisticDto {
  userCount: string;
  companyName?: string;
  companyId?: string;
  formEmployeeCount?: number;
}
