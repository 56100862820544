import './performance-bar.scss'

import { Component, Vue } from 'vue-property-decorator'

@Component({
  template: require('./performance-bar.pug'),
  props: {
    alternative: {
      type: Boolean,
      default: function(): boolean {
        return false
      }
    },
    dateIndicators: {
      type: Boolean,
      default: function(): boolean {
        return false
      }
    },
    minimumValue: {
      type: Number,
      default: function(): number {
        return 0
      }
    },
    maximumValue: {
      type: Number,
      default: function(): number {
        return 100
      }
    },
    targetValue: {
      type: Number,
      default: function(): number {
        return 20
      }
    },
    actualValue: {
      type: Number,
      default: function(): number {
        return 21
      }
    },
    minimumText: {
      type: String,
      default: function(): string {
        return 'Sıfır'
      }
    },
    maximumText: {
      type: String,
      default: function(): string {
        return 'Yüz'
      }
    },
    targetText: {
      type: String,
      default: function(): string {
        return 'Yirmi'
      }
    },
    actualText: {
      type: String,
      default: function(): string {
        return 'Yirmi Bir'
      }
    }
  },
  computed: {
    performanceBarCssVars(): { '--performance-bar-split': number; '--performance-actual-indicator-left': string } {
      let targetPercentage = (this.targetValue - this.minimumValue) / (this.maximumValue - this.minimumValue)
      if (targetPercentage < 0.08) targetPercentage = 0.08
      if (targetPercentage > 0.95) targetPercentage = 0.95
      let actualPercentage = (this.actualValue - this.minimumValue) / (this.maximumValue - this.minimumValue)
      actualPercentage = actualPercentage < 0 ? -0.0275 : actualPercentage > 1 ? 1.0275 : actualPercentage
      return {
        '--performance-bar-split': targetPercentage * 100,
        '--performance-actual-indicator-left': actualPercentage * 100 + '%'
      }
    }
  }
})
export class PerformanceBarComponent extends Vue {
  constructor() {
    super()
  }

  // Props
  private minimumValue: number
  private targetValue: number
  private maximumValue: number
  private actualValue: number
}
