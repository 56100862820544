/**
 * HRNext Performance WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import {
  CommonRequestDto,
  EmployeeTargetCorrelationViewModel,
  PerformanceFormTargetCorrelationDto,
  PerformanceFormTargetCorrelationViewModel,
  YearParameter,
} from './models';
import { LinqService, QueryOptions } from 'linquest';

import { IAjaxProvider } from 'jinqu';

/**
 * PerformanceFormTargetCorrelationApi - API class
 */
export class PerformanceFormTargetCorrelationApi extends LinqService {

  /**
   * Creates a new PerformanceFormTargetCorrelationApi class.
   *
   * @param ajaxProvider The Linquest Ajax Provider to inject. When not specified, default Fetch provider will be used.
   */
  constructor(ajaxProvider?: IAjaxProvider) {
    super("/performance-api", ajaxProvider);
  }

  /**
   * @param dto 
   */
  addOrUpdateAsync(dto?: PerformanceFormTargetCorrelationDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/AddOrUpdateAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param targetCorrelations 
   */
  addOrUpdateBulk(targetCorrelations?: Array<EmployeeTargetCorrelationViewModel>, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/AddOrUpdateBulk`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = targetCorrelations;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  deleteAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/DeleteAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param id 
   */
  getAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/GetAsync`;
    let query = this.createQuery<PerformanceFormTargetCorrelationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   * @param yearParams 
   */
  getCurrentFormTargetCorrelationDisplay(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/GetCurrentFormTargetCorrelationDisplay`;
    let query = this.createQuery<EmployeeTargetCorrelationViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param id 
   */
  getTranslatedAsync(id?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/GetTranslatedAsync`;
    let query = this.createQuery<PerformanceFormTargetCorrelationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'Id', value: id && id.toString() });
    query = query.withOptions(options);

    return query.firstAsync();
  }

  /**
   */
  list(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/List`;
    let query = this.createQuery<PerformanceFormTargetCorrelationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param yearParams 
   */
  listRecommendedTargetsOfCurrentUser(yearParams?: YearParameter, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/ListRecommendedTargetsOfCurrentUser`;
    let query = this.createQuery<PerformanceFormTargetCorrelationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = yearParams;
    query = query.withOptions(options);

    return query;
  }

  /**
   * @param employeeId 
   * @param year 
   */
  listRecommendedTargetsOfEmployee(request: CommonRequestDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/ListRecommendedTargetsOfEmployee`;
    let query = this.createQuery<PerformanceFormTargetCorrelationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = request;
    query = query.withOptions(options);

    return query;
  }

  /**
   */
  listTranslated(options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/ListTranslated`;
    let query = this.createQuery<PerformanceFormTargetCorrelationDto>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    query = query.withOptions(options);

    return query;
  }

  /**
   * @param dto 
   */
  saveAsync(dto?: PerformanceFormTargetCorrelationDto, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/SaveAsync`;
    let query = this.createQuery<any>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'POST';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set body parameter
    options.data = dto;
    query = query.withOptions(options);

    return query.firstAsync();
  }

  
  /**
   * @param performanceFormDetailId 
   */
  getCurrentFormTargetCorrelatioByPerformanceFormDetailIdDisplay(performanceFormDetailId?: string, options?: QueryOptions) {
    // create query
    const url = `/PerformanceFormTargetCorrelation/GetCurrentFormTargetCorrelatioByPerformanceFormDetailIdDisplay`;
    let query = this.createQuery<PerformanceFormTargetCorrelationViewModel>(url);

    // initialize options
    options = options || {};
    options.method = options.method || 'GET';
    options.params = options.params || [];
    options.headers = options.headers || {};

    // set query parameters
    options.params.push({ key: 'performanceFormDetailId', value: performanceFormDetailId && performanceFormDetailId.toString() });
    query = query.withOptions(options);

    return query;
  }
}
