import './timing-bar.scss'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  template: require('./timing-bar.pug'),
  props: {
    barRange: {
      type: Array,
      default: function(): Date[] {
        // start of this year to end of this year
        return [
          moment()
            .startOf('year')
            .toDate(),
          moment()
            .endOf('year')
            .toDate()
        ]
      }
    },
    minimumValue: {
      type: Number,
      default: function(): number {
        return 0
      }
    },
    maximumValue: {
      type: Number,
      default: function(): number {
        return 100
      }
    },
    targetValue: {
      type: Number,
      default: function(): number {
        return 20
      }
    },
    actualValue: {
      type: Number,
      default: function(): number {
        return 21
      }
    },
    minimumText: {
      type: String,
      default: function(): string {
        return 'Sıfır'
      }
    },
    maximumText: {
      type: String,
      default: function(): string {
        return 'Yüz'
      }
    },
    targetText: {
      type: String,
      default: function(): string {
        return 'Yirmi'
      }
    },
    actualText: {
      type: String,
      default: function(): string {
        return 'Yirmi Bir'
      }
    }
  }
})
export class TimingBarComponent extends Vue {
  constructor() {
    super()
  }

  // Props
  private barRange: Date[]
  private minimumValue: number
  private targetValue: number
  private maximumValue: number
  private actualValue: number
  private timingBarCssVars: {
    '--timing-bar-left-margin': string
    '--timing-bar-right-margin': string
    '--timing-bar-split': number
    '--timing-bar-actual-indicator-left': string
  }

  created(): void {
    this.setCssVars()
  }

  barRangeText(): string[] {
    const monthsBetweenDates = []
    const start = moment(this.barRange[0])
    const end = moment(this.barRange[1]).endOf('month')
    const current = start.clone()

    while (current.isBefore(end)) {
      monthsBetweenDates.push(current.format('MMM'))
      current.add(1, 'month')
    }

    return monthsBetweenDates
  }

  get isActualValueInBarRange(): boolean {
    return this.actualValue >= this.minimumValue && this.actualValue <= this.maximumValue
  }

  getPseudoDaysBetweenDates(startDate: Date | 0, endDate: Date): number {
    // imagine as every month has 30 days
    let monthsBetweenDates = 0
    const current = moment(startDate)
      .local()
      .clone()
    const end = moment(endDate)
      .startOf('month')
      .local()
    while (current.isBefore(end)) {
      monthsBetweenDates++
      current.add(1, 'month')
    }

    const realEndOfMonth = moment(endDate)
      .endOf('month')
      .local()
      .date()
    const realLastMonthAddition = moment(endDate)
      .local()
      .diff(current, 'days')

    // there is only a shift of about 3% of the section for the first day (25/10,000 for 12 months), which affects about one pixel.
    // it was left this way so that the border on the right remains on the right
    const calculatedLastMonthAddition = (30 / realEndOfMonth) * (realLastMonthAddition ? realLastMonthAddition + 1 : 0)

    return monthsBetweenDates * 30 + calculatedLastMonthAddition
  }

  // min: 10, target: 20, max: 30 => 0.5
  // min: 10, target: 15, max: 30 => 0.25
  // min: 10, target: 10, max: 30 => 0
  // min: 10, target: 30, max: 30 => 1
  setCssVars(): void {
    const barMinimum = this.getPseudoDaysBetweenDates(0, this.barRange[0])
    const barMaximum = this.getPseudoDaysBetweenDates(
      0,
      moment(this.barRange[1])
        .endOf('month')
        .toDate()
    )
    const targetPercentage = (this.targetValue - this.minimumValue) / (this.maximumValue - this.minimumValue)
    const actualPercentage = (this.actualValue - this.minimumValue) / (this.maximumValue - this.minimumValue)

    // bar left and right margin is calculated like this:
    // imagine a bar with 100px width
    // if minimum value is 5 and maximum value is 30 (25 is between them)
    // and barMinimum is 1 and barMaximum is 51 (50 is between them)
    // bar left margin will be (5-1)/(51-1) = 4/50 = 8%
    // bar right margin will be (51-30)/(51-1) = 21/50 = 42%
    // so the bar will be 50% of the bar
    const leftMargin = ((this.minimumValue - barMinimum) / (barMaximum - barMinimum)) * 100
    const rightMargin = ((barMaximum - this.maximumValue) / (barMaximum - barMinimum)) * 100
    this.timingBarCssVars = {
      '--timing-bar-left-margin': leftMargin + '%',
      '--timing-bar-right-margin': rightMargin + '%',
      '--timing-bar-split': targetPercentage * 100,
      '--timing-bar-actual-indicator-left': actualPercentage * 100 + '%'
    }
  }
}
